import {Box, Typography} from '@mui/material';

const styles = {
    root: {
        display: 'flex' as const,
        flexDirection: 'column' as const,
        gap: 5,
        width: 'calc( 100% - 20px)',
        maxWidth: 1270,
        margin: '0 auto',
        padding: '0px 10px',
    },
    infoText: {
        color: '#565656',
        fontSize: '16px',
        fontFamily: 'Roboto',
        lineHeight: '25px',
    },
};

interface MainContentWithTitleProps {
    title: string;
    text: string;
}

const MainContentWithTitle = (props: MainContentWithTitleProps) => {
    return (
        <Box sx={styles.root}>
            <Typography variant={'h4'}>
                {props.title}

            </Typography>
            <Box style={styles.infoText}>
                {props.text}
            </Box>
        </Box>
    );
};
export default MainContentWithTitle;
