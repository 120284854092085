
export interface ConfigType {
    environment: 'development'|'production';
    app: {
        version: string;
        name: string;
    };
    api: {
        cms: {
            baseUrl: string;
            apiKey: string;
            projectId: number;
            proxyBaseUrl: string;
        };
    };
    keycloak: {
        baseUrl: string;
        realm: string;
        clientId: string;
    };
}

const Config: ConfigType = {
    environment: process.env.REACT_APP_ENV! as 'development'|'production',
    app: {
        version: process.env.REACT_APP_VERSION!,
        name: process.env.REACT_APP_NAME!,
    },
    api: {
        cms: {
            baseUrl: process.env.REACT_APP_API_CMS_BASE_URL!,
            apiKey: process.env.REACT_APP_API_CMS_API_KEY!,
            projectId: Number(process.env.REACT_APP_API_CMS_PROJECT_ID!),
            proxyBaseUrl: process.env.REACT_APP_API_CMS_PROXY_BASE_URL!,
        },
    },
    keycloak: {
        baseUrl: process.env.REACT_APP_KC_BASE_URL!,
        realm: process.env.REACT_APP_KC_REALM!,
        clientId: process.env.REACT_APP_KC_CLIENT_ID!,
    },
};

export default Config;
