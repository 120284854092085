import {call, fork, put, take} from 'redux-saga/effects';
import Command from '../../action/command';
import Event from '../../action/event';
import Api from '../../../api';
import {ClassificationContent} from '../../types/content';
import {ApiCmsContent} from '../../../api/types';
// @ts-ignore
import he from 'he';

const CONTENT_CONTAINER_ID = 21;

const extractClassificationContentsFromRawContents = (contents: ApiCmsContent[]): ClassificationContent[] => {
    return contents
        .map(content => ({
            id: he.decode(content['id']),
            name: he.decode(content['contents']['name']['content']),
            street: he.decode(content['contents']['strasse']['content']),
            zip: he.decode(content['contents']['postleitzahl']['content']),
            city: he.decode(content['contents']['ort']['content']),
            category_type: he.decode(content['contents']['typ']['content']),
            classification_type: he.decode(content['contents']['art']['content']),
            hotel_classificationType: he.decode(content['contents']['hotelklassifizierung']['content']),
            rank: he.decode(content['contents']['sterne']['content']),
        }));
};

const onFetchClassificationContent = function*() {
    const contents: ApiCmsContent[] = yield call(
        Api.fetchContentList,
        CONTENT_CONTAINER_ID,
        ['name', 'strasse', 'postleitzahl', 'ort', 'typ', 'art', 'hotelklassifizierung', 'sterne'],
    );
    const classificationContents: ClassificationContent[] = yield call(
        extractClassificationContentsFromRawContents,
        contents,
    );
    yield put(Event.Content.classificationsContentsFetched({ classificationContents }));
};

export function* fetchClassificationContentsFlow() {
    while (true) {
        yield take(Command.Content.fetchClassificationContents.toString());
        yield fork(onFetchClassificationContent);
    }
};
