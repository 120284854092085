import {Box} from '@mui/material';
import LoginContentListElement from './LoginContentListElement';

const listElementsMock = [
    '20 % sparen bei der GEMA',
    'Kostenfreie Rechtsberatung',
    'Bis zu 45 % günstiger - Hotel- und G-Klassifizierung',
    'Brandenburger Gastlichkeit',
    'Energiekosten sparen - mit Rahmenverträgen',
];
const listelements = listElementsMock;

const styles = {
    root: {
        display: 'flex',
        width: 'calc(50% - 2px)',
        gap: '10px',
        flexWrap: 'wrap' as const,
    },
};

const LoginContentList = () => {
    return (
        <Box style={styles.root}>
            {listelements.map((entry: string) => (
                <LoginContentListElement title={entry} />
            ))}
        </Box>
    );
};

export default LoginContentList;
