import {createTheme, responsiveFontSizes} from '@mui/material';
import palette from './palette';
import typography from './typography';

declare module '@mui/material/styles' {
    interface Theme {
    }
    // allow configuration using `createTheme`
    interface ThemeOptions {
    }
}

let theme = createTheme({
    palette,
    typography,
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 0,
                },
            },
        },
    },
});

theme = responsiveFontSizes(theme);

export default theme;
