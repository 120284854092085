import {Box} from '@mui/material';
import React from 'react';
const styles = {
    root: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: 'rgb(190, 159, 86)',
        color: 'white',
        padding: '10px 20px 10px 40px',
        fontSize: '22px',
        gap: '10px',
        maxWidth: 'calc( 1270px - 60px)',
        alignItems: 'center',
        width: 'calc( 100% - 60px)',
    },
    link: {
        textDecoration: 'none',
        color: 'white',
        fontWeight: '400',
        fontSize: '16px',
    },
    title: {
        fontWeight: '600!important',
        '& > div': {
            fontWeight: '600!important',
        },
    },
};

interface HighlightBarProps {
    text: string;
    icon?: string;
    link?: string;
    linktarget?: string;
    linktitle?: string;
    detailContentId?: string;
    detailContentTitle?: string;
    jumpMark?: string;
}

const HighlightBar = (props: HighlightBarProps) => {
    /*const saveTitle = encodeURI(props.detailContentTitle ? props.detailContentTitle : '');*/
    return (
        <Box id={props.jumpMark || ''} sx={styles.root} className={'noMargin'}>
            <Box sx={styles.title}>
                {props.icon ? <Box>{props.icon}</Box> : ''}
                <Box>{props.text}</Box>
            </Box>
            {/*{props.detailContentTitle &&
            <Link
                style={styles.link}
                to={
                    '/magazin/details/' +
                    props.detailContentId +
                    '/' +
                    saveTitle
                }
                target={'_self'}
            >
                » {props.linktitle ? props.linktitle : 'alle ansehen'}
            </Link>}*/}
        </Box>
    );
};

export default HighlightBar;
