import {DateTime} from 'luxon';

const FORMAT_DATE_OUTPUT_FORMAT = 'dd.MM.yyyy';

const formatDate = (dateString: string): string|null => {
    const dateTime = DateTime.fromISO(dateString);

    if (dateTime.isValid) {
        return dateTime.toFormat(FORMAT_DATE_OUTPUT_FORMAT);
    }

    const dateTimeFromCustomFormat = DateTime.fromFormat(dateString, 'yyyy-MM-dd HH:mm:ss');
    return dateTimeFromCustomFormat.isValid ? dateTimeFromCustomFormat.toFormat(FORMAT_DATE_OUTPUT_FORMAT) : null;
};

export default formatDate;
