import {call, fork, put, take} from 'redux-saga/effects';
import Command from '../../action/command';
import Event from '../../action/event';
import Api from '../../../api';
import {MemberContent} from '../../types/content';
import {ApiCmsContent} from '../../../api/types';
// @ts-ignore
import he from 'he';

const CONTENT_CONTAINER_ID = 20;
const extractMemberContentsFromRawContents = (contents: ApiCmsContent[]): MemberContent[] => {
    return contents
        .map(content => ({
            id: he.decode(content['id']),
            name: he.decode(content['contents']['name']['content']),
            company: he.decode(content['contents']['organisation']['content']),
            street: he.decode(content['contents']['strasse']['content']),
            zip: he.decode(content['contents']['postleitzahl']['content']),
            city: he.decode(content['contents']['ort']['content']),
            website: he.decode(content['contents']['internet']['content']),
            phone: he.decode(content['contents']['telefon']['content']),
            email: he.decode(content['contents']['e_mail']['content']),
        }));
};

const onFetchMemberContent = function*() {
    const contents: ApiCmsContent[] = yield call(
        Api.fetchContentList,
        CONTENT_CONTAINER_ID,
        ['name', 'name', 'organisation', 'strasse', 'postleitzahl', 'ort', 'internet', 'telefon', 'e_mail'],
    );
    const memberContents: MemberContent[] = yield call(
        extractMemberContentsFromRawContents,
        contents,
    );
    yield put(Event.Content.memberContentsFetched({ memberContents }));
};

export function* fetchMemberContentsFlow() {
    while (true) {
        yield take(Command.Content.fetchMemberContents.toString());
        yield fork(onFetchMemberContent);
    }
};
