import {combineReducers} from 'redux';
import {ContentState, contentReducer} from './reducer/contentReducer';
import {UserState, userReducer} from './reducer/userReducer';

export interface ReduxState {
    content: ContentState;
    user: UserState;
}

export const rootReducer = combineReducers({
    content: contentReducer,
    user: userReducer,
});
