import {fetchNewsletterContentsFlow} from './fetchNewsletterContentsFlow';
import {fetchClassificationContentsFlow} from './fetchClassificationContentsFlow';
import {fetchContactContentsFlow} from './fetchContactContentsFlow';
import {fetchMemberContentsFlow} from './fetchMemberContentsFlow';
import {fetchEventContentsFlow} from './fetchEventContentsFlow';
import {fetchBannerContentsFlow} from './fetchBannerContentsFlow';

export const contentSagas = [
    fetchNewsletterContentsFlow,
    fetchClassificationContentsFlow,
    fetchContactContentsFlow,
    fetchMemberContentsFlow,
    fetchEventContentsFlow,
    fetchBannerContentsFlow,
];
