import React, {useEffect} from 'react';
import MainPage from './components/pages/MainPage';
import TilePage from './components/pages/TilePage';
import {Box, ThemeProvider} from '@mui/material';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import LoginPage from './components/pages/LoginPage';
import {store} from './state/store';
import theme from './mui/theme';
import {Provider} from 'react-redux';
import NotFoundPage from './components/pages/NotFoundPage';
import Command from './state/action/command';
import MagazinePageMain from './components/pages/MagazinePageMain';
import Keycloak from './keycloak';
import Event from './state/action/event';
import EventPage from './components/pages/EventPage';
import ScrollToTop from './components/basic/ScrollToTop';
import DehogaMagazinePageMain from './components/pages/DehogaMagazinePageMain';

const initializeKeycloak = () => {
    Keycloak.initialize().then(loggedIn => {
        if (loggedIn) {
            const tokenParsed = Keycloak.instance.tokenParsed!;
            store.dispatch(Event.User.userAuthenticated({
                id: tokenParsed.sub!,
                email: (tokenParsed as any).email || undefined,
                firstName: (tokenParsed as any).given_name || undefined,
                lastName: (tokenParsed as any).family_name || undefined,
            }));
        }

        store.dispatch(Event.User.authProviderInitialized({}));
    });
};

const App = () => {
    useEffect(() => {
        initializeKeycloak();
        setTimeout(
            () => store.dispatch(Command.Content.fetchNewsletterContents({})), 50,
        );
        store.dispatch(Command.Content.fetchClassificationContents({}));
        store.dispatch(Command.Content.fetchContactContents({}));
        store.dispatch(Command.Content.fetchMemberContents({}));
        store.dispatch(Command.Content.fetchEventContents({}));
        store.dispatch(Command.Content.fetchBannerContents({}));
    }, []);

    return (
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <BrowserRouter>
                    <Box>
                        <ScrollToTop>
                            <Routes>
                                <Route path={'*'} element={<NotFoundPage />} />
                                <Route path={'/'} element={<TilePage />} />
                                <Route path={'/search/:contentIds'} element={<TilePage />} />
                                <Route path={'/gewerbe'} element={<TilePage />} />
                                <Route path={'/login'} element={<LoginPage />} />
                                <Route path={'/magazin'} element={<MagazinePageMain />} />
                                <Route path={'/dehoga-magazin'} element={<DehogaMagazinePageMain />} />
                                <Route path={'/details/:id/:name'} element={<MainPage />} />
                                <Route
                                    path={'/details/:id/:name/:hash'}
                                    element={<MainPage />}
                                />
                                <Route
                                    path={'/magazin/details/:id/:name'}
                                    element={<MainPage contentType={'magazine'} />}
                                />
                                <Route
                                    path={'/magazin/details/:id/:name/:hash'}
                                    element={<MainPage contentType={'magazine'} />}
                                />
                                <Route
                                    path={'/event/:id'}
                                    element={<EventPage />}
                                />
                                <Route />
                            </Routes>
                        </ScrollToTop>
                    </Box>
                </BrowserRouter>
            </ThemeProvider>
        </Provider>
    );
};

export default App;
