import {Box} from '@mui/material';
import React from 'react';
import ratingImg from '../../../../../assets/star-solid.svg';
import ClassificationType from './ClassificationType';

const styles = {
    root: {
        marginBottom: '35px',
        '& > div': {
            marginBottom: '10px',
        },
    },
    headline: {
        fontSize: '1.1em',
        fontWeight: '600!important',
        color: '#004189',
        lineHeight: '1.334',
        wordBreak: 'break-all',
    },
    ratingBar: {
        marginBottom: '3px',
        '& img': {
            filter: 'invert(68%) sepia(18%) saturate(910%) hue-rotate(4deg) brightness(89%) contrast(100%)',
            width: '22px',
        },
        fontWeight: 500,
        color: 'rgb(190, 159, 86)',
        display: 'flex',
        gap: '6px',

    },
    classificationEntry: {
        marginBottom: '20px!important',
    },
};

interface ClassificationListProps {
    title: string;
    item: ClassificationType[];
    limit?: number;
    categoryType?: string;
}

const ClassificationList = (props: ClassificationListProps) => {

    const limit = props.limit ? props.limit : 0;

    const items = props.item.sort(
        (a: ClassificationType, b: ClassificationType) => b.rank.localeCompare(a.rank),
    );

    return (
        <Box sx={styles.root}>
            <Box sx={styles.headline}>
                {props.title}
            </Box>
            {items ? items.map(
                (entry: ClassificationType, index) => {
                    if (limit && limit > index || limit < 1) {
                        return(
                            <Box sx={styles.classificationEntry} key={entry.id}>
                                <Box sx={styles.ratingBar}>
                                    {
                                        props.categoryType === 'Gästehäuser, Gasthöfe & Pensionen' &&
                                        <Box>G</Box>
                                    }
                                    <Box>
                                        {
                                            Array(parseInt(entry.rank)).fill(0).map(
                                                () => {
                                                    return(<img key={Math.random()} src={ratingImg} />);
                                                },
                                            )
                                        }
                                    </Box>
                                    {
                                        entry.hotel_classificationType &&
                                        <Box>{entry.hotel_classificationType}</Box>
                                    }
                                </Box>
                                <Box>{entry.name}, {entry.zip} {entry.city}</Box>
                            </Box>
                        );
                    }
                    return(<></>);
                },
            ) : ''}
        </Box>
    );
};

export default ClassificationList;
