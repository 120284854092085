import {Action, handleActions} from 'redux-actions';
import {
    BannerContent,
    ClassificationContent,
    ContactContent,
    EventContent,
    MemberContent,
    NewsletterContent,
} from '../types/content';
import Event from '../action/event';
import {
    BannerContentsFetchedPayload,
    ClassificationContentsFetchedPayload,
    ContactContentsFetchedPayload, EventContentsFetchedPayload, MemberContentsFetchedPayload,
    NewsletterContentsFetchedPayload,
} from '../action/contentEvent';

export interface ContentState {
    newsletterContents: NewsletterContent[];
    classificationContents: ClassificationContent[];
    contactContents: ContactContent[];
    memberContents: MemberContent[];
    eventContents: EventContent[];
    bannerContents: BannerContent[];
}

export const contentInitialState: ContentState = {
    newsletterContents: [],
    classificationContents: [],
    contactContents: [],
    memberContents: [],
    eventContents: [],
    bannerContents: [],
};

/* eslint-disable max-len */
export const contentReducer = handleActions<ContentState, any>(
    {
        [Event.Content.newsletterContentsFetched.toString()]: (state, action: Action<NewsletterContentsFetchedPayload>): ContentState => {
            return {
                ...state,
                newsletterContents: action.payload.newsletterContents,
            };
        },
        [Event.Content.classificationsContentsFetched.toString()]: (state, action: Action<ClassificationContentsFetchedPayload>): ContentState => {
            return {
                ...state,
                classificationContents: action.payload.classificationContents,
            };
        },
        [Event.Content.contactContentsFetched.toString()]: (state, action: Action<ContactContentsFetchedPayload>): ContentState => {
            return {
                ...state,
                contactContents: action.payload.contactContents,
            };
        },

        [Event.Content.memberContentsFetched.toString()]: (state, action: Action<MemberContentsFetchedPayload>): ContentState => {
            return {
                ...state,
                memberContents: action.payload.memberContents,
            };
        },
        [Event.Content.eventContentsFetched.toString()]: (state, action: Action<EventContentsFetchedPayload>): ContentState => {
            return {
                ...state,
                eventContents: action.payload.eventContents,
            };
        },
        [Event.Content.bannerContentsFetched.toString()]: (state, action: Action<BannerContentsFetchedPayload>): ContentState => {
            return {
                ...state,
                bannerContents: action.payload.bannerContents,
            };
        },
    },
    contentInitialState,
);
