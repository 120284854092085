import {Button, styled} from '@mui/material';

const LeftAngleButton = styled(Button)`
    :before {
        content: '«';
        padding-right: 2px;
    }
`;

export default LeftAngleButton;
