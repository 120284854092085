import React from 'react';
import {Link} from 'react-router-dom';
import RightAngleButton from './RightAngleButton';
import ContentTypeType from '../common/Content/Tiles/contentTypeType';
import isExternalLink from './isExternalLink';
import getDefaultTarget from './getDefaultTarget';

const styles = {
    tilebutton: {
        width: 'fit-content',
        height: '30px',
        color: '',
        borderColor: '',
        marginTop: '20px',
        border: '2px solid',
    },
};

interface RouterButtonInterface {
    title: string;
    link: string;
    target?: string;
    color?: string;
    type?: ContentTypeType;
}

const RouterButton = (props: RouterButtonInterface) => {
    const contentTypeStyles = props.type ? {
        ...styles.tilebutton,
        color: props.type.buttonColor,
        borderColor: props.type.buttonColor,
    }
        :
        styles.tilebutton;

    const tilebuttonStyles = props.color ? {
        ...contentTypeStyles,
        color: props.color,
        borderColor: props.color,
    }
        :
        contentTypeStyles;

    const isExternalLinkBool = isExternalLink(props.link);
    const defaultTarget = getDefaultTarget(isExternalLinkBool);

    if (isExternalLinkBool) {
        return (
            <RightAngleButton
                style={tilebuttonStyles}
                variant={'outlined'}
                onClick={() => {window.open(props.link, (props.target ? props.target : defaultTarget));}}
            >{props.title}</RightAngleButton>
        );
    }
    return (
        <Link to={props.link} target={props.target ? props.target : defaultTarget}>
            <RightAngleButton
                style={tilebuttonStyles}
                variant={'outlined'}
            >{props.title}</RightAngleButton>
        </Link>
    );
};

export default RouterButton;
