import Event from '../action/event';
import {Action, handleActions} from 'redux-actions';
import {UserAuthenticatedPayload} from '../action/userEvent';
import {User} from '../types/user';

export interface UserState {
    initialized: boolean;
    user: null|User;
}

export const userInitialState: UserState = {
    initialized: false,
    user: null,
};

/* eslint-disable max-len */
export const userReducer = handleActions<UserState, any>(
    {
        [Event.User.authProviderInitialized.toString()]: (state, action: Action<UserAuthenticatedPayload>): UserState => {
            return {
                ...state,
                initialized: true,
            };
        },
        [Event.User.userAuthenticated.toString()]: (state, action: Action<UserAuthenticatedPayload>): UserState => {
            return {
                ...state,
                user: {
                    id: action.payload.id,
                    email: action.payload.email || null,
                    firstName: action.payload.firstName || null,
                    lastName: action.payload.lastName || null,
                },
            };
        },
    },
    userInitialState,
);
