import {useParams} from 'react-router-dom';
import {Box, Typography} from '@mui/material';
import {useSelector} from 'react-redux';
import Selector from '../../state/selector';
import Header from '../common/Header';
import Footer from '../common/Footer';
import formatDate from '../basic/formatDate';
import './EventPage.css';

const styles = {
    content: {
        width: 'calc(100% - 40px)',
        maxWidth: '1270px',
        margin: '0px auto',
        padding: '0px 20px',
    },
    place: {
        marginBottom: '5px',
        color: 'rgb(0, 65, 135)',
        fontWeight: '400',
        fontSize: '20px',
    },
};

interface EventPageProps {

}

const EventPage = (props: EventPageProps) => {
    const { id } = useParams();
    const evenContents = useSelector(Selector.Content.eventContents);
    const event = evenContents.find(eventContent => eventContent.id === id);

    return (
        <Box>
            <Header />s
            {event && (
                <Box sx={styles.content}>
                    <Box sx={styles.place}>
                        {event.place}
                    </Box>
                    <Typography variant={'h2'}>
                        {formatDate(event.date)} &nbsp;|&nbsp; {event.title}
                    </Typography>
                    <Typography
                        className={'eventPageLinkColor'}
                        variant={'body1'}
                        dangerouslySetInnerHTML={{ __html: event.text }}
                    />
                </Box>
            )}
            <Footer />
        </Box>
    );
};

export default EventPage;
