import TileTypeImage from '../common/Content/Tiles/TileTypeImage';

const checkNewsletterCategorys = (tileList: TileTypeImage[]) => {
    const validCategorys = [
        'Aus dem Förderkreis',
        'Aktuelles aus Politik & Wirtschaft',
        'Verbandsleben',
        'Angebote',
    ];

    const output: TileTypeImage[] = [];
    tileList.map(
        (entry) => {
            if (validCategorys.includes(entry.categorys)) {
                output.push(entry);

            }
            return '';

        },
    );
    return output;
};

export default checkNewsletterCategorys;
