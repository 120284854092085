import { Box } from '@mui/material';
import LeftAngleButton from '../basic/LeftAngleButton';
import RightAngleButton from '../basic/RightAngleButton';
import ShareModal from '../common/MainPage/ShareModal';
import { Link, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import Selector from '../../state/selector';
import Footer from '../common/Footer';
import Header from '../common/Header';
import Keycloak from '../../keycloak';
import sha256 from 'crypto-js/sha256';

const styles = {
    root: {
        width: 'calc( 100% - 40px)',
        maxWidth: 1270,
        margin: '0 auto',
        padding: '0px 20px',
    },
    logo: {
        width: '100%',

    },
    textboxWrapper: {
        display: 'flex',
        gap: '40px',
        marginTop: '55px',
        flexWrap: 'wrap' as const,
    },
    imageWrapper: {
        width: 'calc(40% - 35px)',
        minWidth: 350,
        '@media (max-width: 930px)': {
            width: '100%',
            minWidth: 'unset',
        },
    },
    headline: {
        color: '#004187',
        fontFamily: 'Saira Condensed',
        fontSize: '2.6rem',
        fontWeight: 700,
    },
    infoText: {
        color: '#565656',
        width: 'calc( 60% -  35px)',
        fontFamily: 'Roboto',
        lineHeight: '25px',
        '@media (max-width: 930px)': {
            width: '100%',
        },
    },
    btnspacer: {
        marginRight: 2.5,
    },
    buttons: {
        marginTop: '50px',
        '& button': {
            padding: '0px 6px',
        },
    },
    backButton: {
        padding: '0',
    },
};

const getContent = (providedId: string | undefined, contentList: any) => {
    for (let index = 0; contentList.length > index; index++) {
        const content = contentList[index];
        if (content && content.id === providedId) {
            return {
                previous: contentList[index - 1] ? contentList[index - 1] : null,
                current: contentList[index] ? contentList[index] : null,
                next: contentList[index + 1] ? contentList[index + 1] : null,
            };
        }
    }
};

const CONTAINER_ID = 16;

interface MainPageProps {
    contentType?: string;
}

const MainPage = (props: MainPageProps) => {
    const { id, hash } = useParams();
    const authProviderInitialized = useSelector(Selector.User.initialized);
    const isLoggedIn = useSelector(Selector.User.isLoggedIn);
    const newsletterContents = useSelector(Selector.Content.newsletterContents);
    const contacts = useSelector(Selector.Content.contactContents);
    const content = getContent(id, newsletterContents);

    const hashDigest = sha256(id + '___' + CONTAINER_ID);
    const hashValid = hashDigest.toString() === hash;
    const isAllowedToAccessPage = content?.current.locked === false || isLoggedIn || hashValid;

    const [profileImage, setProfileImage] = useState<any>('');

    useEffect(() => {
        if(!content){return;}
        const contactId = content.current?.contact[0]?.content_id ? content.current?.contact[0]?.content_id : undefined;
        const image = contactId ?
            contacts.filter(content => content['id'] === contactId)[0]?.imagePath :
            content!.current?.contact[0]?.imagePath;
        setProfileImage(
            image ? image : content ? content.current.imagePath : null,
        );
    }, [content, contacts, newsletterContents]);


    useEffect(() => {
        if (authProviderInitialized && !isAllowedToAccessPage && content) {
            Keycloak.login();
        }
    }, [authProviderInitialized, isAllowedToAccessPage]);

    if (!isAllowedToAccessPage) {
        return null;
    }

    const title = content?.current?.title || '';
    const text = content?.current?.text || '';
    const prevId = content?.previous?.id || '';
    const prevTitle = content?.previous?.title || content?.previous?.categorys || '';
    const nextId = content?.next?.id || '';
    const nextTitle = content?.next?.title || content?.next?.categorys || '';

    







    const baseLink = (props.contentType === 'magazine') ? '/magazin/details/' : '/details/';

    return (
        <Box>
            <Header type={props.contentType && props.contentType} />
            <Box style={styles.root}>
                <RightAngleButton
                    style={styles.backButton}
                    variant={'outlined'}
                    onClick={
                        () => {
                            window.history.back();
                        }
                    }
                >zurück</RightAngleButton>
                <Box style={styles.headline}>
                    {title}
                </Box>
                <Box>
                    <ShareModal />
                </Box>
                <Box style={styles.textboxWrapper}>
                    <Box sx={styles.infoText}>
                        <div dangerouslySetInnerHTML={{ __html: text}} />
                    </Box>
                    <Box sx={styles.imageWrapper}>
                        <img src={profileImage ? profileImage : ''} style={styles.logo} alt={''} />
                    </Box>
                </Box>
                <Box sx={styles.buttons}>
                    {prevId && (
                        <Link to={baseLink + prevId + '/' + encodeURIComponent(prevTitle)}>
                            <LeftAngleButton
                                sx={styles.btnspacer}
                                variant={'outlined'}
                            >zurück</LeftAngleButton>
                        </Link>
                    )}
                    {nextId !== '' ?
                        <Link to={baseLink + nextId + '/' + encodeURIComponent(nextTitle)}>
                            <RightAngleButton
                                variant={'outlined'}
                            >
                                weiter
                            </RightAngleButton>
                        </Link>
                        : ''}
                </Box>
            </Box>
            <Footer />

            {/* Meta Tags */}
            <Helmet>
                <meta property={'og:title'} content={title.substring(0, 94)} />
                <title>{title}</title>

                <meta property={'og:description'} content={text.replace(/(<([^>]+)>)/gi, '').substring(0, 296)} />
                <meta name={'description'} content={text.replace(/(<([^>]+)>)/gi, '').substring(0, 296)} />

                <meta property={'og:image'} content={profileImage ? profileImage : ''} />

                <meta property={'og:url'} content={window.location.href} />

                <meta property={'og:type'} content={'article'} />
            </Helmet>
        </Box>
    );
};

export default MainPage;
