import { Box, Button, IconButton, Modal, Stack } from '@mui/material';
import ReplyIcon from '@mui/icons-material/Reply';
import CloseIcon from '@mui/icons-material/Close';
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import React from 'react';

const styles = {

    shareButton: {
        color: '#004187',
        fontFamily: 'Roboto',
        fontSize: '1.1rem',
        marginTop: '5px',
    },

    shareButtonIcon: {
        transform: 'scaleX(-1)',
    },

    root: {
        position: 'absolute' as const,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        borderRadius: '10px',
        boxShadow: 24,
        p: 4,
    },

    closeButtonModal: {
        color: '#004187',
        marginTop: '-20px',
        left: '95%',
        fontSize: '2.6rem',
    },

    shareHeaderText: {
        color: '#004187',
        fontSize: '2.6rem',
        marginTop: '-20px',
        marginLeft: '20px',
        marginBottom: '10px',
        
    },

    shareText: {
        color: '#004187',
        fontSize: '2.6rem',
        
    },

    color: {
        color: '#004187',
        width: '100%',
    },
};



interface ShareModalProps {
}

const tmp = window.location.href;

const URL = encodeURIComponent(tmp);

const facebookLink = 'https://www.facebook.com/sharer/sharer.php' + 
    '?u=' + URL.replace('localhost', 'localhost.de') +
    '&t=Seht euch diesen Artikel auf gastgewerbe-in-brandenburg.de an!';

const facebookLink2 = 'https://www.facebook.com/sharer/sharer.php' + 
    '?s=100' +
    '&p[title]= Schaut euch diesen Artikel an!' +
    '&p[url]=' + URL;

const whatsappLink = 'https://api.whatsapp.com/send/' + 
    '?text=' + URL.replace('localhost', 'localhost.de') +
    '&type=custom_url&app_absent=0';

const mailLink = 'mailto:' + 
    '?subject=Empfehlung Artikel auf gastgewerbe-in-brandenburg.de' + 
    '&body=' + tmp;

const ShareModal = (props: ShareModalProps) => {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const openFacebook = () => window.open(facebookLink, '_blank');
    const openWhatsapp = () => window.open(whatsappLink, '_blank');

    return (
        <Box>
            <Button
                onClick={handleOpen}
                variant={'text'}
                startIcon={<ReplyIcon style={styles.shareButtonIcon} sx={{ fontSize: 40 }} />}
                style={styles.shareButton}
            >
                Teilen
            </Button>
            <Box>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby={'modal-modal-title'}
                    aria-describedby={'modal-modal-description'}
                >
                    <Box
                        sx={styles.root}
                    >
                        <Box
                            style={styles.color}
                        >
                            <Button
                                onClick={handleClose}
                                variant={'text'}
                                style={styles.closeButtonModal}
                            >
                                <CloseIcon sx={{ fontSize: 40 }} />
                            </Button>
                        </Box>
                        <h1 style={styles.shareHeaderText}>
                            Empfehlen
                        </h1>
                        <Stack
                            direction={'row'}
                            spacing={8}
                        >
                            
                            <Button
                                style={styles.shareText}
                                onClick={openFacebook}
                            >
                                <Stack>
                                    <FacebookIcon style={styles.color} sx={{ fontSize: 99 }} />
                                    Facebook
                                </Stack>
                            </Button>
                            <Button
                                style={styles.shareText}
                                onClick={openWhatsapp}
                            >
                                <Stack>
                                    <WhatsAppIcon style={styles.color} sx={{ fontSize: 99 }} />
                                    WhatsApp
                                </Stack>
                            </Button>
                            <Button
                                href={mailLink}
                                style={styles.shareText}
                            >
                                <Stack>
                                    <EmailOutlinedIcon style={styles.color} sx={{ fontSize: 99 }} />
                                    E-Mail
                                </Stack>
                            </Button>
                        </Stack>
                    </Box>
                </Modal>
            </Box>
        </Box>
    );
};

export default ShareModal;
