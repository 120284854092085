import {call, fork, put, take} from 'redux-saga/effects';
import Command from '../../action/command';
import Event from '../../action/event';
import Api from '../../../api';
import {NewsletterContent} from '../../types/content';
import {ApiCmsContent} from '../../../api/types';
// @ts-ignore
import he from 'he';
import Config from '../../../util/config';
import fixUmlauts from '../../../components/basic/fixUmlauts';
import contentContainerIdStringToDictArr from '../../../components/basic/contentContainerIdStringToDictArr';

const CONTENT_CONTAINER_ID = 16;

const PROJECTID = Config.api.cms.projectId;
const API_BASE_URI = Config.api.cms.baseUrl;

const extractNewsletterContentsFromRawContents = (contents: ApiCmsContent[]): NewsletterContent[] => {

    return contents
        .filter(content => content['contents']['versteckt']['content'] === 'false')
        .map(content => {
            let contacts = [];
            let events = [];
            let topicContents = [];
            let banner = [];

            if(content['contents']['ansprechpartner']['contentset'] !== undefined) {
                contacts = content['contents']['ansprechpartner']['contentset'];
            }

            if(content['contents']['termine']['contentset'] !== undefined) {
                events = content['contents']['termine']['contentset'];
            }

            if(content['contents']['themeninhalte']['contentset'] !== undefined) {
                topicContents = content['contents']['themeninhalte']['contentset'];
            }

            if(content['contents']['werbung']['contentset'] !== undefined) {
                banner = content['contents']['werbung']['contentset'];
            }

            let published = false;
            if(content['published'] === 'true') {
                published = true;
            }
            return {
                published: published,
                title: fixUmlauts(he.decode(content['contents']['titel']['content'])),
                text: fixUmlauts(he.decode(content['contents']['text']['content'])),
                imagePath: getImagePath(content),
                locked: content['contents']['gesperrt']['content'] !== 'false',
                id: he.decode(content['id']),
                date: he.decode(content['contents']['datum']['content']),
                stateDate: he.decode(content['contents']['stand']['content']),
                categorys: he.decode(content['contents']['kategorie']['content']),
                contact: contentContainerIdStringToDictArr(contacts),
                events: contentContainerIdStringToDictArr(events),
                topicContents: contentContainerIdStringToDictArr(topicContents),
                banner: contentContainerIdStringToDictArr(banner),
            };
        });
};

const getImagePath = (content: any) => {
    const imgId = content['contents']['bild']['media_id'];

    return imgId === 0 ?
        '/placeholder.png'
        :
        API_BASE_URI + 'media-manager/project/' +
            PROJECTID +
            '/directory/0/file/' +
            imgId +
            '/raw';
};

const onFetchNewsletterContents = function*() {
    const contents: ApiCmsContent[] = yield call(
        Api.fetchContentList,
        CONTENT_CONTAINER_ID,
        [
            'titel',
            'text',
            'bild',
            'gesperrt',
            'versteckt',
            'datum',
            'kategorie',
            'ansprechpartner',
            'termine',
            'themeninhalte',
            'werbung',
            'stand',
        ],
    );
    const newsletterContents: NewsletterContent[] = yield call(extractNewsletterContentsFromRawContents, contents);
    yield put(Event.Content.newsletterContentsFetched({ newsletterContents }));
};

export function* fetchNewsletterContentsFlow() {
    while (true) {
        yield take(Command.Content.fetchNewsletterContents.toString());
        yield fork(onFetchNewsletterContents);
    }
};

