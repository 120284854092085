import {Box} from '@mui/material';
import logo from '../../../assets/test_icon.png';

interface ListProps {
    title: string;
}

const styles = {
    root: {
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        width: '49%',
    },
    text: {
        fontSize: '19px',
        color: '#004189',
    },
    logostyle: {
        width: '40px',
        height: 'auto',
    },
};

const LoginContentListElement = (props: ListProps) => {
    return (
        <Box style={styles.root}>
            <img style={styles.logostyle} src={logo} alt={''} />
            <Box style={styles.text}>{props.title}</Box>
        </Box>
    );
};

export default LoginContentListElement;
