import {Box} from '@mui/material';

const magazineStyle = {
    box1: {
        backgroundColor: '#be9f56',
        position: 'absolute' as const,
        top: -100,
        left: -100,
        right: -100,
        bottom: 100,
        transform: 'rotate(6deg)',
        zIndex: -1,
    },
};

const styles = {
    box1: {
        backgroundColor: '#004187aa',
        position: 'absolute' as const,
        top: -100,
        left: -100,
        right: -100,
        bottom: 100,
        transform: 'rotate(6deg)',
        zIndex: -1,
    },
    box2: {
        backgroundColor: '#004187aa',
        position: 'absolute' as const,
        top: -100,
        left: -1000,
        right: -100,
        bottom: 100,
        transform: 'rotate(-6deg)',
        zIndex: -1,
    },
    box3: {
        backgroundColor: '#004187',
        position: 'absolute' as const,
        top: -100,
        left: -100,
        right: -100,
        bottom: 150,
        transform: 'rotate(3deg)',
        zIndex: -1,
    },
};

interface HeaderBackgroundProps {
    type?: string;
};

const HeaderBackground = (props: HeaderBackgroundProps) => {
    return (<Box>
        <Box style={(props.type === 'magazine') ? magazineStyle.box1 : styles.box1} />
        <Box style={styles.box2} />
        <Box style={styles.box3} />
    </Box>);
};

export default HeaderBackground;
