import React from 'react';
import {Box, Link} from '@mui/material';

const styles = {
    root: {
        '@media (max-width: 1000px)': {
            flexDirection: 'column',
        },
        maxWidth: '1270px',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        '& > div:first-of-type': {
            width: '500px',
            padding: '40px',
            '& > div:first-of-type': {
                marginBottom: '10px',
            },
            '@media (max-width: 1000px)': {
                width: 'calc(100% - 80px)',
            },
        },
        '& > div:nth-of-type(2)': {
            width: '100%',
            gap: '10px',
            padding: '40px',
            display: 'flex',
            flexWrap: 'wrap' as const,
            height: 'fit-content',
            '& > div': {
                width: 'calc(50% - 10px)',
            },
            '@media (max-width: 1000px)': {
                width: 'calc(100% - 40px)',
                padding: '20px',
            },
            '@media (max-width: 440px)': {
                flexDirection: 'column',
                alignItems: 'center',
            },
        },
    },
    headline: {
        fontWeight: '600!important',
        color: '#004189',
        lineHeight: '1.334',
        fontSize: '1.16em',
    },
    link: {
        margin: '10px 0px 10px 0',
        display: 'inline-block',
    },
    entry: {
        '@media (max-width: 440px)': {
            width: '100%!important',
        },
    },
};

type MemberType = {
    id: string;
    name: string;
    zip: string;
    street: string;
    city: string;
    company: string;
    website: string;
    email: string;
}

interface NewMembersProps {
    title: string;
    text: string;
    members: MemberType[];
    limit: number;
}

const NewMembers = (props: NewMembersProps) => {
    const limit = props.limit ? props.limit : 0;
    const members = props.members.sort(
        (a: MemberType, b: MemberType) => a.company.localeCompare(b.company),
    );

    return (
        <Box id={'members'} sx={styles.root}>
            <Box>
                <Box sx={styles.headline}>{props.title}</Box>
                <Box>
                    {props.text}
                </Box>
            </Box>
            <Box>
                {members ?
                    members.map(
                        (member: MemberType, index) => {
                            if (limit > index || limit === 0) {

                                let websiteTag;
                                if (member.website) {
                                    let href;
                                    if(/^(:\/\/)/.test(member.website)){
                                        href = `https${member.website}`;
                                    } else {
                                        href = `https://${member.website}`;
                                    }

                                    websiteTag = <Link sx={styles.link} href={href} target={'_blank'}>
                                        {member.website}
                                    </Link>;
                                }

                                return(
                                    <Box sx={styles.entry} key={Math.random()}>
                                        <Box sx={styles.headline}>{member.company}</Box>
                                        <Box>{member.name}</Box>
                                        <Box>{member.street}, {member.zip} {member.city}</Box>

                                        {websiteTag}
                                    </Box>
                                );
                            }

                        },
                    )
                    :
                    ''
                }
            </Box>
        </Box>
    );
};

export default NewMembers;
