const fixUmlauts = (input: string) => {
    //@todo add more elegant solution
    input = input.replace(/&auml;/g, 'ä');
    input = input.replace(/&ouml;/g, 'ö');
    input = input.replace(/&uuml;/g, 'ü');
    input = input.replace(/&szlig;/g, 'ß');
    input = input.replace(/&Auml;/g, 'Ä');
    input = input.replace(/&Ouml;/g, 'Ö');
    input = input.replace(/&Uuml;/g, 'Ü');
    return input;
};

export default fixUmlauts;
