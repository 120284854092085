import {Typography} from '@mui/material';

interface NotFoundPageProps {

}

const NotFoundPage = (props: NotFoundPageProps) => {
    return (
        <Typography variant={'body1'}>Leider konnten wir diese Seite nicht Servieren</Typography>
    );
};

export default NotFoundPage;
