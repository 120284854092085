import {ReduxState} from '../reducer';

const newsletterContents = (state: ReduxState) => state.content.newsletterContents;
const classificationContents = (state: ReduxState) => state.content.classificationContents;
const contactContents = (state: ReduxState) => state.content.contactContents;
const memberContents = (state: ReduxState) => state.content.memberContents;
const eventContents = (state: ReduxState) => state.content.eventContents;
const bannerContents = (state: ReduxState) => state.content.bannerContents;

const ContentSelector = {
    newsletterContents,
    classificationContents,
    contactContents,
    memberContents,
    eventContents,
    bannerContents,
};

export default ContentSelector;
