import React from 'react';
import {Box} from '@mui/material';
import Image from '../basic/Image';
import ImageType from '../basic/ImageType';

const styles = {
    root: {
        maxWidth: '1270px',
        width: '100%',
    },
};

interface AdboxProps {
    image: ImageType;
    width?: string;
}

const getWidth = (widthString: string) => {
    if (widthString === 'half') {
        return '645px';
    }
    return '1270px';
};

const Adbox = (props: AdboxProps) => {
    const BoxStyle = props.width ? {
        ...styles.root,
        maxWidth: getWidth(props.width),
    }
        :
        styles.root;
    return (
        <Box sx={BoxStyle}>
            <Image image={props.image} />
        </Box>
    );
};

export default Adbox;
