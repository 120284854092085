/*@TODO LIMITTEXT TOO HACKY REFACTORING IS NEEDED*/
const limitText = (text: string, limit: number, textsuffix: string = ''): string => {
    const openTag = text.lastIndexOf('<strong>');
    const closeTag = text.lastIndexOf('</strong>');
    if (text.charAt(limit) !== ' ') {
        return limitText(text, limit - 1);
    }
    else {
        if (openTag > closeTag) {
            return text.slice(0, limit) + '</strong> ...';
        } else {
            return text.slice(0, limit) + ' ...';
        }
    }
};


const LimitText = (inputText: string, length: number, title: string) => {
    const limitLength = length - title.length;
    if (inputText.length > length) {
        return limitText(inputText, length);
    } else {
        return inputText;
    }
};

export default LimitText;
