import {FormControl, InputLabel, MenuItem, Select} from '@mui/material';
import {useState} from 'react';
import TileType from './Tiles/TileTypeImage';

const styles = {
    root: {
        '& .MuiSelect-outlined': {
            padding: '6px 10px' as const,
        },
        '& .MuiInputLabel-root[data-shrink="false"]': {
            transform: 'translate(14px, 5px) scale(1)' as const,
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: 0,
            maxHeight: '32px',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: 0,
        },
    },
    filterbox: {
        minWidth: '120px',
        backgroundColor: 'white',
    },
    inputpadding: {
        padding: '0px',
    },
};

interface Tileprops {
    filterEntry: any;
    entryList: TileType[];
    filterClear: boolean;
    onFilterUnclear: any; /*@TODO HILFE dies ist die Funktion eines setUsestates wie heisst hier der type*/
    setFilters: any;/*@TODO HILFE  dies ist die Funktion eines setUsestates wie heisst hier der type*/
}

const FilterBarSelect = (props: Tileprops) => {
    const [filterVal, setFilterVal] = useState('');
    const updateFilters = (event: any, props: Tileprops) => { /*@TODO HILFE  wie ist der type für ein Event*/
        setFilterVal(event.target.value);
        props.onFilterUnclear(false);
        props.setFilters((filters: string[]) => ({
            ...filters,
            [props.filterEntry.name]: event.target.value,
        }));
    };
    if (props.filterClear && filterVal !== '') {
        setFilterVal('');
        props.setFilters([]);
    }
    return (
        <FormControl sx={styles.root}>
            <InputLabel id={'category-input-label'}>{props.filterEntry.title}</InputLabel>
            <Select
                style={styles.filterbox}
                labelId={'category-input-label'}
                id={'category-select'}
                label={'Kategorie'}
                onChange={(e) => {updateFilters(e, props);}}
                defaultValue={''}
                value={filterVal}
            >
                {props.entryList.map((entryData: any) => (
                    /*@TODO HILFE  bekomme den type nicht hin*/
                    <MenuItem key={entryData.toString()} value={entryData.toString()}>{entryData.toString()}</MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default FilterBarSelect;
