import {Box} from '@mui/material';
import Loginfield from './Loginfield';
import RightAngleButton from '../../basic/RightAngleButton';
import keylocklogo from '../../../assets/schloss.png';

const styles = {
    root: {
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        backgroundColor: '#99b3d1',
        width: 'calc(50% - 2px)',

        '& .MuiSelect-outlined': {
            padding: '6px 10px' as const,
        },
        '& .MuiInputLabel-root[data-shrink="false"]': {
            transform: 'translate(14px, 5px) scale(1)' as const,
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: 0,
            maxHeight: '32px',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: 0,
        },
    },
    loginheader: {
        fontFamily: 'Saira Condensed',
        fontSize: '37px',
        color: '#004189',
        fontWeight: 700,
        display: 'flex',
        flexDirection: 'column' as const,
        gap: '15px',
        width: '100%',
        padding: '25px 45px',
        justifyContent: 'flex-start',
    },
    textbox: {
        width: '300px',
        backgroundColor: 'white',
    },
    inputpadding: {
        padding: '0px',
    },
    loginbutton: {
        backgroundColor: '#004189',
        color: 'white',
        width: '160px',
        fontSize: '20px',
        fontFamily: 'Saira Condensed',
    },
    clicktext: {
        fontSize: '15px',
        marginLeft: '395px',
        cursor: 'pointer',
        fontFamily: 'Roboto',
    },
    lockstyle: {
        width: '30px',
        height: '40px',
        paddingBottom: '10px',
    },
    keyheader: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: '15px',
    },
};

const LoginBox = () => {
    return (
        <Box style={styles.root}>
            <Box style={styles.loginheader}>
                <Box style={styles.keyheader}>
                    <img style={styles.lockstyle} src={keylocklogo} alt={''} />
                    <Box>Login Mitglieder</Box>
                </Box>
                <Loginfield label={'Mitgliedsnummer'} />
                <Loginfield type={'password'} label={'Passwort'} />
                <Box
                    style={styles.clicktext}
                    onClick={() => {window.open('https://www.dehoga-brandenburg.de/', '_blank');}}
                >Passwort vergessen?</Box>
                <RightAngleButton style={styles.loginbutton} variant={'outlined'}>Jetzt einloggen</RightAngleButton>
            </Box>
        </Box>
    );
};

export default LoginBox;
