import {call, fork, put, take} from 'redux-saga/effects';
import Command from '../../action/command';
import Event from '../../action/event';
import Api from '../../../api';
import {ContactContent} from '../../types/content';
import {ApiCmsContent} from '../../../api/types';
import Config from '../../../util/config';
// @ts-ignore
import he from 'he';
const CONTENT_CONTAINER_ID = 18;
const getImagePath = (imageId: string) => {
    return parseInt(imageId) === 0 ?
        ''
        :
        Config.api.cms.baseUrl +
        'media-manager/project/' +
        Config.api.cms.projectId +
        '/directory/0/file/' +
        imageId +
        '/raw';
};

const extractContactContentsFromRawContents = (contents: ApiCmsContent[]): ContactContent[] => {
    return contents
        .map(content => ({
            id: he.decode(content['id']),
            imagePath: getImagePath(he.decode(content['contents']['bild']['media_id'])),
            name: he.decode(content['contents']['name']['content']),
            position: he.decode(content['contents']['position']['content']),
        }));
};

const onFetchContactContent = function*() {
    const contents: ApiCmsContent[] = yield call(
        Api.fetchContentList,
        CONTENT_CONTAINER_ID,
        ['bild', 'name', 'position'],
    );
    const contactContents: ContactContent[] = yield call(
        extractContactContentsFromRawContents,
        contents,
    );
    yield put(Event.Content.contactContentsFetched({ contactContents }));
};

export function* fetchContactContentsFlow() {
    while (true) {
        yield take(Command.Content.fetchContactContents.toString());
        yield fork(onFetchContactContent);
    }
};
