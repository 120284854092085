import {createAction} from 'redux-actions';

const createContentCommand = <T>(type: string) => createAction<T>('_/CCT/' + type);

export interface FetchNewsletterContentsPayload {
}
export interface FetchClassificationContentsPayload {
}
export interface FetchContactContentsPayload {
}
export interface FetchContactMemberPayload {
}
export interface FetchEventContentsPayload {
}
export interface FetchBannerContentsPayload {
}

const ContentCommand = {
    fetchNewsletterContents:
        createContentCommand<FetchNewsletterContentsPayload>('FETCH_NEWSLETTER_CONTENTS'),
    fetchClassificationContents:
        createContentCommand<FetchClassificationContentsPayload>('FETCH_CLASSIFICATION_CONTENTS'),
    fetchContactContents:
        createContentCommand<FetchContactContentsPayload>('FETCH_CONTACT_CONTENTS'),
    fetchMemberContents:
        createContentCommand<FetchContactMemberPayload>('FETCH_MEMBER_CONTENTS'),
    fetchEventContents:
        createContentCommand<FetchEventContentsPayload>('FETCH_EVENT_CONTENTS'),
    fetchBannerContents:
        createContentCommand<FetchBannerContentsPayload>('FETCH_BANNER_CONTENTS'),
};

export default ContentCommand;
