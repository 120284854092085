import {Box, Theme, Typography} from '@mui/material';
import RightAngleButton from '../../basic/RightAngleButton';
import SearchBox from './SearchBox';
import {Link} from 'react-router-dom';
import React from 'react';


const styles = {
    root: {
        display: 'grid',
        gridTemplateColumns: 'auto 635px 635px auto',
        width: '100%',
        margin: '0 auto',
        marginTop: '40px',
        '@media only screen and (max-width: 1300px)': {
            paddingLeft: '20px',
            paddingRight: '20px',
            width: 'calc( 100% - 80px)',
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap' as const,
            gap: '20px',
        },

        '@media only screen and (max-width: 370px)': {
            paddingLeft: '10px',
            paddingRight: '10px',
            width: 'calc( 100% - 20px)',
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap' as const,
            gap: '20px',
        },

        '@media only screen and (max-width: 970px)': {
            justifyContent: 'center',
            gap: '40px',
        },

    },

    magazineBar: {
        backgroundColor: 'rgb(190, 159, 86)',
        height: '54px',
        marginTop: '45px',
        '@media only screen and (max-width: 1300px)': {
            display: 'none',
        },

    },
    leftContainer: {
        gridColumn: 2,
        '& h1': {
            color: 'white',
            textTransform: 'uppercase' as const,
            fontWeight: '500',
            fontSize: '2.4rem',
        },
        '& h2': {
            color: 'white',
            textTransform: 'uppercase' as const,
            fontWeight: '700',
        },
    },
    rightContainer: {
        gridColumn: 3,
        '@media (max-width: 767px)': {
            justifyContent: 'center',
            display: 'flex',
            paddingTop: '20px',
        },
    },
    buttonContainer: (theme: Theme) => ({
        marginTop: theme.spacing(6),
        display: 'flex',
        flexWrap: 'wrap',
        gap: '10px',
        '@media (max-width: 360px)': {
            '& button': {
                padding: '5px',
            },
        },
    }),
    openWebsiteButton: {
        marginRight: 2.5,
    },
};

interface HeaderContentProps {
    type?: string;
    headline?: string;
    subline?: string;
};

const HeaderContent = (props: HeaderContentProps) => {
    let markedHeadLine = {};
    if (props.type === 'magazine') {
        markedHeadLine = {
            backgroundColor: 'rgb(190, 159, 86)',
            width: 'fit-content',
            padding: '7px 13px 7px 0px',
        };
    }

    return (
        <Box sx={styles.root}>
            {props.type === 'magazine' ? <Box sx={styles.magazineBar}></Box> : '' }
            <Box sx={styles.leftContainer}>
                <Typography variant={'h1'} style={{ color: 'white' }}>
                    { props.headline }
                </Typography>
                <Box>
                    <Typography
                        variant={'h2'}
                        style={markedHeadLine}
                    >
                        { props.subline }
                    </Typography>
                </Box>

                <Box sx={styles.buttonContainer}>
                    <RightAngleButton
                        onClick={() => {window.open('https://www.dehoga-brandenburg.de/', '_blank');}}
                        sx={styles.openWebsiteButton}
                        variant={'contained'}
                    >
                        zur DEHOGA Brandenburg Website
                    </RightAngleButton>
                    {/*{props.type === 'magazine' ? '' :
                        <Link to={'/magazin'}>
                            <RightAngleButton
                                variant={'contained'}
                                color={'secondary'}
                            >zum DEHOGA Magazin</RightAngleButton>
                        </Link>
                    }*/}
                </Box>
            </Box>
            <Box sx={styles.rightContainer}>
                <SearchBox />
            </Box>
        </Box>
    );
};

export default HeaderContent;
