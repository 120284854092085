import {ContentSets} from '../../state/types/content';

const contentContainerIdStringToDictArr = (input: []) => {
    const contents = input;
    const output = contents.map(
        (content: ContentSets) => {
            return(
                {
                    container_id: content.container_id,
                    content_id: content.content_id,
                }
            );
        },
    );
    return output;
};

export default contentContainerIdStringToDictArr;
