import {Box} from '@mui/material';
import Keycloak from '../../keycloak';
import {useSelector} from 'react-redux';
import Selector from '../../state/selector';
import {useNavigate} from 'react-router';

const styles = {
    root: {
        position: 'relative',
        overflow: 'hidden',
    },
    textWrapper: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: '200px',
        paddingBottom: '50px',

    },
    textBox: {
        width: '100%',
        maxWidth: '1270px',
        color: 'white',

        '& strong': {
            color: 'white',
        },

        '& > div': {
            marginBottom: '15px',
        },

        '& > div:nth-of-type(1)': {
            fontSize: '14px',
            '@media (max-width: 600px)': {
                textAlign: 'center',
            },
        },
        '@media (max-width: 1300px)': {
            paddingLeft: '20px',
            width: 'calc( 100% - 40px)',
        },
        '@media (max-width: 600px)': {
            paddingLeft: '0px',
        },
    },
    boxWrapper: {
        position: 'absolute',
        left: 0,
        top: '40px',
        width: '100%',
    },
    box1: {
        backgroundColor: '#004187',
        position: 'absolute' as const,
        top: '25px',
        left: -100,
        right: -100,
        bottom: 100,
        transform: 'rotate(3deg) translateY(110px)',
        zIndex: -1,
        height: '800px',
    },
};

const Footer = () => {
    const navigate = useNavigate();
    const isLoggedIn = useSelector(Selector.User.isLoggedIn);

    const logout = () => {
        navigate('/');
        Keycloak.logout();
    };

    return (
        <Box sx={styles.root} id={'MYFOOTER'}>
            <Box style={styles.textWrapper}>
                <Box sx={styles.textBox}>
                    <Box>So erreichen Sie uns</Box>
                    <Box>
                        <Box>
                            <strong>IMPRESSUM:</strong> <br />
                            Herausgeber: Deutscher Hotel- und Gastst&auml;ttenverband Brandenburg e.V.
                            (DEHOGA Brandenburg)<br />
                            Schwarzschildstra&szlig;e 94 &bull;
                            14480 Potsdam &bull;
                            Tel.: 0331 - 86 23 68 &bull;
                            Fax: 0331 - 86 23 81 &bull;
                            E-Mail: info@dehoga-brandenburg.de &bull;
                            Internet: www.dehoga-brandenburg.de
                        </Box>
                    </Box>
                    <Box>@ DEHOGA Brandenburg</Box>
                    {isLoggedIn && <button onClick={logout}>Logout</button>}
                </Box>
            </Box>
            <Box sx={styles.boxWrapper}>
                <Box sx={styles.box1}></Box>
            </Box>
        </Box>
    );
};

export default Footer;
