import {createAction} from 'redux-actions';
import {
    BannerContent,
    ClassificationContent,
    ContactContent,
    EventContent,
    MemberContent,
    NewsletterContent,
} from '../types/content';

const createContentEvent = <T>(type: string) => createAction<T>('_/ECT/' + type);

export interface NewsletterContentsFetchedPayload {
    newsletterContents: NewsletterContent[];
}

export interface ClassificationContentsFetchedPayload {
    classificationContents: ClassificationContent[];
}

export interface ContactContentsFetchedPayload {
    contactContents: ContactContent[];
}

export interface MemberContentsFetchedPayload {
    memberContents: MemberContent[];
}

export interface EventContentsFetchedPayload {
    eventContents: EventContent[];
}
export interface BannerContentsFetchedPayload {
    bannerContents: BannerContent[];
}

const ContentEvent = {
    newsletterContentsFetched:
        createContentEvent<NewsletterContentsFetchedPayload>('NEWSLETTER_CONTENTS_FETCHED'),
    classificationsContentsFetched:
        createContentEvent<ClassificationContentsFetchedPayload>('CLASSIFICATION_CONTENTS_FETCHED'),
    contactContentsFetched:
        createContentEvent<ContactContentsFetchedPayload>('CONTACT_CONTENTS_FETCHED'),
    memberContentsFetched:
        createContentEvent<MemberContentsFetchedPayload>('MEMBER_CONTENTS_FETCHED'),
    eventContentsFetched:
        createContentEvent<EventContentsFetchedPayload>('EVENT_CONTENTS_FETCHED'),
    bannerContentsFetched:
        createContentEvent<BannerContentsFetchedPayload>('BANNER_CONTENTS_FETCHED'),
};

export default ContentEvent;
