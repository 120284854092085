import {Button, styled} from '@mui/material';

const RightAngleButton = styled(Button)`
    :before {
        content: '»';
        padding-right: 2px;
    }
`;

export default RightAngleButton;
