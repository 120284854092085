import {FormControl, TextField} from '@mui/material';

const styles = {
    root: {
        '& .MuiSelect-outlined': {
            padding: '6px 10px' as const,
        },
        '& .MuiInputLabel-root[data-shrink="false"]': {
            transform: 'translate(14px, 9px) scale(1)' as const,
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: 0,
            maxHeight: '40px',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: 0,
        },
        height: '40px',
    },

    textbox: {
        width: '100%',
        backgroundColor: 'white',
        height: '40px',
    },

};

interface Loginprops {
    label: string;
    type?: string;
}
const Loginfield = (props: Loginprops) => {
    return (
        <FormControl sx={styles.root}>
            <TextField
                type={props.type}
                name={props.label}
                style={styles.textbox}
                label={props.label}
                variant={'outlined'}
            />
        </FormControl>
    );
};

export default Loginfield;






