import {Box} from '@mui/material';
import logo from '/app/src/assets/dehoga_logo--placeholder.png';
/*import {useSelector} from 'react-redux';
import Selector from '../../../state/selector';
import stripHtmlTags from '../../basic/stripHtmlTags';*/

const styles = {
    root: {
        display: 'flex' as const,
        flexDirection: 'column' as const,
        width: 300,
        margin: '0 auto',
        marginRight: '0px',
        '@media (max-width: 767px)': {
            margin: '0!important',
        },
    },
    logo: {
        width: '100%',
        height: 60,
        marginBottom: 15,
    },
    input: {
        backgroundColor: 'white' as const,
        borderRadius: 0,
    },
    searchWrapper: {
        position: 'relative' as const,
        display: 'flex' as const,
        justifyContent: 'center' as const,
        alignItems: 'center' as const,

    },
    searchIcon: {
        position: 'absolute' as const,
        right: 7,
        cursor: 'pointer' as const,
    },
    textfieldstyle: {
        '& ': {
            backgroundColor: 'white',
            borderColor: 'none',
            border: 'none',
            paddingRight: 25,
            width: '100%',
        },
        '& .MuiFormControl-root': {
            width: '100%',
        },
        '& .MuiOutlinedInput-input': {
            padding: '10px!important',
        },
        '& .Mui-focused': {
            borderColor: 'none',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'none',
            border: 'none',
        },
        '& .MuiInputLabel-root.Mui-focused ': {
            display: 'none',
        },
        '& .MuiInputLabel-root.Mui-animated ': {
            display: 'none',
        },
        '& .MuiInputLabel-root[data-shrink="true"]': {
            display: 'none',
        },
        '& .MuiInputLabel-root ': {
            opacity: 1,
            lineHeight: '1em',
        },
    },
};

/*const getSearchResult = (keywords: string[], text: string) => {
    for (const keyword in keywords) {
        if (text.includes(keyword)) {
            return true;
        }
    }
    return false;
};*/

/*const search = (keyword: string, contents: any[]) => {
    const validContents = [];
    for (const content of contents) {
        const searchSuccess = getSearchResult(
            keyword.toLocaleLowerCase().split(' '),
            stripHtmlTags(content['title'].toLocaleLowerCase() + ' ' + content['text'].toLocaleLowerCase()),
        );
        if (searchSuccess) {
            validContents.push(content.id);
        }
    }
};*/

const SearchBox = () => {
    /*const newsletterContents = useSelector(Selector.Content.newsletterContentsSelector);*/
    return (
        <Box sx={styles.root}>
            <img src={logo} style={styles.logo} alt={''} />

            {/*<Box style={styles.searchWrapper}>
                <TextField label={'Schlagwortsuche'} sx={styles.textfieldstyle} variant={'outlined'} />
                <SearchIcon
                    style={styles.searchIcon}
                    onClick={() => {search('Umsatzverluste noch', newsletterContents);}}
                />
            </Box>*/}
        </Box>
    );
};
export default SearchBox;
