import {call, fork, put, take} from 'redux-saga/effects';
import Command from '../../action/command';
import Event from '../../action/event';
import Api from '../../../api';
import {ApiCmsContent} from '../../../api/types';
// @ts-ignore
import he from 'he';
import {BannerContent} from '../../types/content';
import getImagePath from '../../../components/basic/getImagePath';

const CONTENT_CONTAINER_ID = 19;
const extractBannerContentsFromRawContents = (contents: ApiCmsContent[]): BannerContent[] => {
    return contents
        .map(content => ({
            id: he.decode(content['id']),
            title: he.decode(content['contents']['titel']['content']),
            imagePath: getImagePath(he.decode(content['contents']['banner']['media_id'])),
            url: he.decode(content['contents']['zielseite']['content']),
        }));
};

const onFetchBannerContent = function*() {
    const contents: ApiCmsContent[] = yield call(
        Api.fetchContentList,
        CONTENT_CONTAINER_ID,
        ['titel', 'banner', 'zielseite'],
    );
    const bannerContents: BannerContent[] = yield call(
        extractBannerContentsFromRawContents,
        contents,
    );

    yield put(Event.Content.bannerContentsFetched({ bannerContents }));
};

export function* fetchBannerContentsFlow() {
    while (true) {
        yield take(Command.Content.fetchBannerContents.toString());
        yield fork(onFetchBannerContent);
    }
};
