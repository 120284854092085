import axios, {AxiosRequestConfig} from 'axios';
import Config from '../util/config';
import {ApiCmsContent} from './types';

const cmsAxiosInstance = axios.create({
    baseURL: Config.api.cms.proxyBaseUrl,
});

const sendCmsApiRequest = async (config: AxiosRequestConfig) => {
    return cmsAxiosInstance({
        ...config,
        params: {
            ...config.params,
            apiKey: Config.api.cms.apiKey,
            lang: 'de', // @todo hardcoded
        },
    });
};

const Api = {
    fetchContentList: async (containerId: number, details?: string[]): Promise<ApiCmsContent[]> => {
        const response = await sendCmsApiRequest({
            url: `project/${Config.api.cms.projectId}/container/${containerId}/contents`,
            method: 'get',
            params: !details ? {} : {
                details: details.join(','),
            },
        });

        return response.data['message'];
    },
};

export default Api;
