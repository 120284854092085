import {Box} from '@mui/material';
import React from 'react';
import Image from '../../../../basic/Image';
import ClassificationType from './ClassificationType';
import ClassificationList from './ClassificationList';

const styles = {
    root: {
        width: '100%',
        maxWidth: 1270,
        display: 'flex',
        gap: '25px',
        height: 'fit-content',
        '& > div:first-of-type img': {
            width: '100%',
        },
        '& > div ': {
            width: 'calc(30% - 15px)',
            display: 'flex',
            flexWrap: 'wrap',
            gap: '20px',
            fontWeight: '300',
            padding: '20px',
        },
        '& > div:nth-of-type(2) ': {
            width: 'calc( 100% - 40px)',
        },
        '@media (max-width: 1000px)': {
            width: '100%',
            flexDirection: 'column',
        },
    },
    description: {
        height: 'fit-content',
        '@media (max-width: 1000px)': {
            width: 'calc( 100% - 80px)!important',
            display: 'flex',
            flexDirection: 'row',
            '& > div:first-of-type': {
                height: '200px',
                width: '200px',
            },
            '& > div:nth-of-type(2)': {
                width: 'calc(100% - 220px)',
            },
        },
    },
    headline: {
        fontSize: '1.1em',
        fontWeight: '600!important',
        color: '#004189',
        lineHeight: '1.334',
    },
    classificationHeadline: {
        fontSize: '1.1em',
        fontWeight: '600!important',
        color: '#004189',
        lineHeight: '1.334',
        borderBottom: '1px solid rgb(0, 65, 137)',
        marginBottom: '30px',
        paddingBottom: '12px',
    },
    classificationWrapper: {
        gap: '10px',
        display: 'flex',
        flexDirection: 'row' as const,
        '& > div': {
            width: 'calc( 50% - 10px)',
        },
        '@media (max-width: 1000px)': {
            width: 'calc(100% - 80px)',
        },
        '@media (max-width: 635px)': {
            alignOItems: 'center',
            flexDirection: 'column',
            '& > div': {
                width: '100%',
            },
        },
    },
};

interface ClassificationTileProps {
    logo: string;
    title: string;
    text: string;
    stateDate: string|null;
    classificationList?: ClassificationType[];
    backgroundColor: string;
    limit: number;
}

const SortClassificationList = (classificationList: ClassificationType[]) => {
    const hotelFirst: ClassificationType[] = [];
    const hotelRepetition: ClassificationType[] = [];
    const otherFirst: ClassificationType[] = [];
    const otherRepetition: ClassificationType[] = [];

    classificationList.map((item) => {
        if (item.category_type === 'Hotelklassifizierung') {
            if (item.classification_type === 'Erstklassifizierung') {
                hotelFirst.push(item);
            }
            if (item.classification_type === 'Wiederholungsklassifizierung') {
                hotelRepetition.push(item);
            }
        }

        if (item.category_type === 'Gästehäuser, Gasthöfe & Pensionen') {
            if (item.classification_type === 'Erstklassifizierung') {
                otherFirst.push(item);
            }
            if (item.classification_type === 'Wiederholungsklassifizierung') {
                otherRepetition.push(item);
            }
        }
        return '';
    });
    const hotel = [hotelFirst, hotelRepetition];
    const other = [otherFirst, otherRepetition];
    return [hotel, other];
};

const ClassificationTile = (props: ClassificationTileProps) => {
    const limit = props.limit;
    const rootStyles = props.backgroundColor ? {
        ...styles.root,
        backgroundColor: props.backgroundColor,
    }
        :
        styles.root;
    const classificationSorted = props.classificationList && SortClassificationList(props.classificationList);

    /* eslint-disable-next-line no-console */
    console.log(classificationSorted);

    return (
        <Box sx={rootStyles}>
            <Box sx={styles.description}>
                <Image image={{imagePath: props.logo}} />
                <Box sx={styles.headline}>{props.title}</Box>
                <Box>{props.text}</Box>
                <Box>Stand: {props.stateDate}</Box>
            </Box>
            <Box sx={styles.classificationWrapper} id={'test1'}>

                {classificationSorted && classificationSorted.map(
                    (classificationGroup) => {
                        const first = classificationGroup[0];
                        const Repetition = classificationGroup[1];

                        let catType = '';
                        if(first.length > 0){
                            catType = first[0].category_type;
                        } else if (Repetition.length > 0){
                            catType = Repetition[0].category_type;
                        }

                        return (
                            <Box key={Math.random()}>
                                <Box style={styles.classificationHeadline}>
                                    <strong>{catType}</strong>

                                </Box>
                                {classificationGroup.map(
                                    (list) => {
                                        /* eslint-disable-next-line no-console */
                                        console.log(list);
                                        if(list.length > 0) {
                                            return (
                                                <ClassificationList
                                                    key={list[0].classification_type}
                                                    title={list[0].classification_type + 'en'}
                                                    item={list}
                                                    limit={limit}
                                                    categoryType={catType}
                                                />
                                            );
                                        }
                                    },
                                )}
                            </Box>
                        );
                    },
                )}
            </Box>
        </Box>
    );
};

export default ClassificationTile;
