import {Box} from '@mui/material';
import FilterBarSelect from './FilterBarSelect';
import {useEffect, useState} from 'react';

const styles = {
    root: {
        display: 'flex' as const,
        flexDirection: 'column' as const,
        width: '100%',
        maxWidth: 1270,
        margin: '0 auto',
        flexWrap: 'wrap' as const,
    },
    notice: {
        fontSize: '12px',
        color: '#565656',
        marginBottom: '15px',
        width: 'calc( 100% - 20px)',
        '@media (max-width: 1270px)': {
            marginLeft: '10px',
        },
    },
    barbackground: {
        backgroundColor: '#EBF0F6',
        width: 'calc(100% - 40px)',
        display: 'flex',
        gap: '20px',
        padding: '20px',
        alignItems: 'center',
        flexWrap: 'wrap' as const,
        '@media (max-width: 583px)': {
            justifyContent: 'center',
        },
    },
    displayButton: {
        width: '120px',
        maxHeight: '32px',
    },
    clearButton: {
        color: '#004187',
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        gap: '5px',
        '& > div:first-of-type': {
            fontSize: '18px',
        },
        '& > div:nth-of-type(2)': {
            fontSize: '14px',
            fontWeight: '400',
        },
    },
};

interface FilterBarProps {
    filter: any;
    onFilterExecution: any;
    tileListSetter: any;
    tileList: any;
}

const clearFilterButton = (variable: any, func: any) => {
    if (!variable) {
        return (<Box onClick={() => {func(true);}} sx={styles.clearButton}>
            <Box>&#10005;</Box>
            <Box>zurücksetzen</Box>
        </Box>);
    }
};

const FilterBar = (props: FilterBarProps) => {
    const [filters, setFilters] = useState({});
    const [clearedFilters, setClearedFilters] = useState(true);

    useEffect(() => {
        props.onFilterExecution(filters, props.tileListSetter, props.tileList);
    }, [filters]);

    return (
        <Box sx={styles.root}>
            <Box sx={styles.notice}>
                Bitte verwenden Sie den Filter, um die Auswahl einzuschränken
            </Box>
            <Box sx={styles.barbackground}>
                {props.filter.map((entry: any) => (
                    <FilterBarSelect
                        key={entry.title}
                        filterClear={clearedFilters}
                        setFilters={setFilters}
                        onFilterUnclear={setClearedFilters}
                        filterEntry={entry}
                        entryList={entry.entryList}
                    />
                ))}
                {clearFilterButton(clearedFilters, setClearedFilters)}
            </Box>
        </Box>
    );
};

export default FilterBar;
