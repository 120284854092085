import {Box, Typography} from '@mui/material';
import React from 'react';
import ContentTypeType from './contentTypeType';
import RouterButton from '../../../basic/RouterButton';
import htmlEntityDecode from '../../../basic/htmlEntityDecode';
import formatDate from '../../../basic/formatDate';
import keylockLogo from '../../../../assets/schloss.png';
import {useSelector} from 'react-redux';
import Selector from '../../../../state/selector';

const styles = {
    root: {
        width: '370px',
        margin: 0,
        minHeight: '500px',
        display: 'flex'as const,
        flexDirection: 'column' as const,
        gap: '5px',
        '@media (max-width: 430px)': {
            minWidth: '200px',
            width: '100%',
        },
        /*marginTop: '30px',
        marginLeft: '35px',*/

    },
    imageWrapper: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '240px',
    },
    image: {
        maxWidth: '100%',
        maxHeight: '100%',
        /*width: '500px',*/
    },
    text: {
        color: '#565656',
        fontFamily: 'Roboto',
        lineHeight: '23px',
        display: '-webkit-box',
        overflow: 'hidden',
        height: 'fit-content',
        maxHeight: '93px',
        WebkitLineClamp: '4',
        WebkitBoxOrient: 'vertical',
    },
    tilebutton: {
        width: '100px',
        height: '30px',
        marginTop: '15px',
    },
    schloss: {
        width: '12px',
        marginLeft: '5px',
        opacity: '0.7',
    },
};

interface TileProps {
    text: string;
    date: string;
    title: string;
    image: string;
    id: string;
    type?: ContentTypeType;
    locked: boolean;
}

const ImageTile = (props: TileProps) => {

    const isLoggedIn = useSelector(Selector.User.isLoggedIn);
    const displayLock = !!(props.locked && !isLoggedIn);
    const dateString = props.date ? formatDate(props.date) : '';

    return (
        <Box sx={styles.root}>
            <picture style={styles.imageWrapper}>
                <source srcSet={props.image + '?width=430 1x, ' + props.image + '?width=860 2x'} />
                <img style={styles.image} src={props.image + '?width=860'} alt={''} />
            </picture>
            <Box>
                <Typography variant={'h5'}>
                    {dateString} | {htmlEntityDecode(props.title)}
                    {displayLock ? <img src={keylockLogo} style={styles.schloss} /> : ''}
                </Typography>
            </Box>
            <Box sx={styles.text}>{htmlEntityDecode(props.text)}</Box>
            <RouterButton
                type={props.type}
                title={'weiterlesen'}
                link={'/details/' + props.id + '/' + encodeURIComponent(props.title)}
            />
        </Box>
    );
};

export default ImageTile;
