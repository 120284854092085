import {Box} from '@mui/material';
import React from 'react';
import SidebarSmall from '../SidebarSmall';
import roundProfileType from '../../../basic/roundProfileType';
import eventType from '../../../basic/eventType';
const styles = {
    root: {
        maxWidth: '1270px',
        width: '100%',
        display: 'flex',
        flexWrap: 'wrap',
        gap: '40px',
        '@media (max-width: 1300px)': {
            paddingLeft: '20px',
            paddingRight: '20px',
            width: 'calc( 100% - 40px)',
        },
        '@media (max-width: 850px)': {
            flexDirection: 'column-reverse',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            paddingLeft: '20px',
            paddingRight: '20px',
        },
    },
    text: {
        width: 'calc( 65% - 20px)',
        fontSize: '20px!important',
        fontWeight: '300',
        lineHeight: '26px',
        color: 'rgb(0, 65, 135)',
        minWidth: '500px',
        '@media (max-width: 630px)': {
            minWidth: 'unset',
            width: 'calc(100% - 20px)',
        },
        '& strong ': {
            fontWeight: '600',
            fontSize: '1.2em',
        },
    },
};

interface TextWithSidebarProps {
    text: string;
    imageBoxData: roundProfileType;
    bookmarks: eventType[];
}

const TextTileBox = (props: TextWithSidebarProps) => {
    return (
        <Box sx={styles.root}>
            <Box sx={styles.text}>
                <div dangerouslySetInnerHTML={{ __html: props.text }}></div>
            </Box>
            <SidebarSmall
                imageBoxData={props.imageBoxData}
                bookmarks={props.bookmarks}
            />
        </Box>
    );
};

export default TextTileBox;
