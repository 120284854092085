import {Box} from '@mui/material';
import MainContentWithTitle from '../common/Content/Tiles/MainContentWithTitle';
import FilterBar from '../common/Content/FilterBar';
import TileList from '../common/Content/Tiles/TileList';
import {useState} from 'react';

import contentMock from '../../mock/tilePageContent';
import TileType from '../common/Content/Tiles/TileTypeImage';
import {useSelector} from 'react-redux';
import Selector from '../../state/selector';
import Header from '../common/Header';
import Footer from '../common/Footer';
import checkNewsletterCategorys from '../basic/checkNewsletterCategorys';

const styles = {
    root: {
        display: 'flex',
        flexDirection: 'column' as const,
        gap: 75,

    },
};

const avMonths = [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember',
];

const handleFilter = (
    filterText: any,
    tilelistSetter: any,
    tileList: TileType[],
) => {
    tilelistSetter([]);
    const elements: TileType[] = [];
    tileList.map(
        (item: TileType) => {
            let validItem = true;
            Object.keys(filterText).map((key: string) => {
                if (key === 'year' || key === 'month') {
                    const itemDate = new Date(item['date']);
                    if (key === 'year') {
                        if (itemDate.getFullYear() !== parseInt(filterText[key])) {
                            validItem = false;
                        }
                    }
                    if (key === 'month') {
                        if (avMonths[itemDate.getMonth()] !== filterText[key]) {
                            validItem = false;
                        }
                    }
                } else {
                    if (item['categorys'] !== filterText[key]) {
                        validItem = false;
                    }
                }
                return '';
            });
            if (validItem) {
                elements.push(item);
            }
            return '';
        },
    );
    tilelistSetter(checkNewsletterCategorys(elements));
};

const getAvailableFilters = (list: TileType[]) => {
    const categorys: string[] = [];
    const months: string[] = [];
    const sortedMonths: string[] = [];
    const years: string[] = [];
    list.map((entry: TileType) => {
        if (!categorys.includes(entry['categorys'])) {
            categorys.push(entry['categorys']);
        }
        const date = new Date(entry['date']);
        const monthId = date.getMonth();
        const month = avMonths[monthId];
        const year = date.getFullYear().toString();
        if (months.indexOf(month) === -1) {
            months.push(month);
        };
        if (years.indexOf(year) === -1) {
            years.push(year);
        };
        return '';
    });

    avMonths.map(
        (monthname: string) => {
            if (months.indexOf(monthname) !== -1) {
                sortedMonths.push(monthname);
            }
            return '';
        },
    );
    years.sort((a, b) => parseInt(a) - parseInt(b));
    return [
        {
            title: 'Kategorie',
            name: 'category',
            entryList: categorys,
        },
        {
            title: 'Monat',
            name: 'month',
            entryList: sortedMonths,
        },
        {
            title: 'Jahr',
            name: 'year',
            entryList: years,
        },
    ];
};

const TilePage = () => {

    const contentText = contentMock['text'];
    const contentTitle = contentMock['title'];
    const newsletterContents = useSelector(Selector.Content.newsletterContents);
    const defaultTileList = checkNewsletterCategorys(newsletterContents);
    const [currentTileList, setCurrentTileList] = useState(defaultTileList);
    const filter = getAvailableFilters(defaultTileList);

    return (
        <Box>
            <Header />
            <Box style={styles.root}>
                <MainContentWithTitle title={contentTitle} text={contentText} />
                <FilterBar
                    onFilterExecution={handleFilter}
                    filter={filter}
                    tileList={newsletterContents}
                    tileListSetter={setCurrentTileList}
                />
                <TileList
                    imageTileList={
                        currentTileList.length === 0 ?
                            defaultTileList
                            :
                            currentTileList
                    }
                />
            </Box>
            <Footer />
        </Box>
    );
};

export default TilePage;
