import {Box} from '@mui/material';
import React from 'react';
import RouterButton from '../../../basic/RouterButton';
import Image from '../../../basic/Image';
import ImageType from '../../../basic/ImageType';
import RoundImageBox from '../RoundImageBox';
import ContentTypeType from './contentTypeType';
import formatDate from '../../../basic/formatDate';

const styles = {
    root: {
        maxWidth: '1270px',
        '& > div > div': {
            marginBottom: '20px',
        },
        '& strong': {
            color: '#004189',
        },
    },
    title: {
        color: '#004189',
        fontWeight: '600',
    },
    textBox: {
        backgroundColor: '',
        columnCount: '3',
        columnGap: '30px',
        width: 'calc( 100% - 80px )',
        paddingLeft: '40px',
        paddingRight: '40px',
        paddingTop: '40px',
        paddingBottom: '50px',
        fontWeight: '300',
        fontSize: '18px',
        '& img': {
            maxWidth: '100%',
            marginTop: '10px',
            marginBottom: '10px',
        },
        '@media (max-width: 1000px)': {
            columnCount: '2',
        },
        '@media (max-width: 600px)': {
            columnCount: '1',
        },
    },
    imageBoxWrapper: {
        '& > div': {
            flexDirection: 'row',
            width: '100%',
            flexWrap: 'unset',
        },
        '& > div > div:nth-of-type(2)': {
            maxWidth: 'calc( 50% - 10px)',
        },
    },
};

type ProfileType = {
    name: string;
    surname: string;
    position: string;
    imagePath?: string;
}

interface TextTileBoxProps {
    profile?: ProfileType;
    backgroundColor?: string;
    text: string;
    image?: ImageType;
    type?: ContentTypeType;
    date?: string;
    title?: string;
    textLimit?: number;
    contentId?: string;
}
const removeUiBreakingTags = (text: string) => {
    return text
        .replace('<pre>', '')
        .replace('</pre>', '');
};

const TextTileBox = (props: TextTileBoxProps) => {
    const textBoxStyles = props.backgroundColor ? {
        ...styles.textBox,
        backgroundColor: props.backgroundColor,
    }
        :
        styles.textBox;

    const dateString = props.date ? formatDate(props.date) : '';

    // const linkName = props.title ? encodeURI(props.title) : encodeURI(props.profile ? props.profile.name : '');
    const linkName = props.profile ? encodeURI(props.profile.name) : props.title ? encodeURI(props.title) : '';

    const detailLink = '/magazin/details/' +
        props.contentId +
        '/' +
        linkName;

    return (
        <Box sx={styles.root}>
            <Box sx={textBoxStyles}>
                {props.profile ?
                    <Box sx={styles.imageBoxWrapper} id={'test'}>
                        <RoundImageBox
                            imagePath={props.profile.imagePath || ''}
                            title={props.profile.name ? props.profile.name : '' +
                                props.profile.surname ? props.profile.surname + ' ' + props.profile.surname : ''}
                            text={props.profile.position}
                            size={200}
                        />
                    </Box> : ''}
                {Boolean(props.title) &&
                    <Box sx={styles.title}>{ dateString ? <>{dateString} | </> : ''} {props.title}</Box>
                }
                <div dangerouslySetInnerHTML={{ __html: removeUiBreakingTags(props.text)}}></div>
                {props.image ? <><br /><Image image={props.image} /><br /></> : ''}
                <RouterButton type={props.type} title={'weiterlesen'} link={detailLink} />
            </Box>
        </Box>
    );
};

export default TextTileBox;
