import {createAction} from 'redux-actions';

const createUserEvent = <T>(type: string) => createAction<T>('_/EUS/' + type);

export interface AuthProviderInitialized {
}

export interface UserAuthenticatedPayload {
    id: string;
    email?: string;
    firstName?: string;
    lastName?: string;
}

const UserEvent = {
    authProviderInitialized: createUserEvent<AuthProviderInitialized>('AUTH_PROVIDER_INITIALIZED'),
    userAuthenticated: createUserEvent<UserAuthenticatedPayload>('USER_AUTHENTICATED'),
};

export default UserEvent;
