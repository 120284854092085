import {Box} from '@mui/material';
import Header from '../common/Header';
import Footer from '../common/Footer';
import Login from '../common/Login';

const subheadLineMock = '<em>Was?Sie sind noch kein Mitglied</em>' +
                        '<br/>Jetzt Mitglied werden und alle Vorteile genießen:';

const headLineMock = 'Hier gehts zum internen Informationsbereich für unsere Mitglieder';

const LoginPage = () => {
    return (
        <Box>
            <Header />
            <Login headline={headLineMock} subheadline={subheadLineMock} />
            <Footer />
        </Box>
    );
};

export default LoginPage;
