import Config from '../../util/config';

const getImagePath = (imageId: string) => {
    return parseInt(imageId) === 0 ?
        ''
        :
        Config.api.cms.baseUrl +
        'media-manager/project/' +
        Config.api.cms.projectId +
        '/directory/0/file/' +
        imageId +
        '/raw';
};

export default getImagePath;
