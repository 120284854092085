import {Box, FormControl, MenuItem, Select, SelectChangeEvent} from '@mui/material';
import React from 'react';
import {NewsletterContent} from '../../../state/types/content';

const styles = {
    root: {
        display: 'flex' as const,
        flexDirection: 'column' as const,
        width: '100%',
        maxWidth: 1270,
        margin: '0 auto',
        flexWrap: 'wrap' as const,
    },
    notice: {
        fontSize: '12px',
        color: '#565656',
        marginBottom: '15px',
        width: 'calc( 100% - 20px)',
        '@media (max-width: 1270px)': {
            marginLeft: '10px',
        },
    },
    barbackground: {
        backgroundColor: '#EBF0F6',
        width: 'calc(100% - 40px)',
        display: 'flex',
        gap: '20px',
        padding: '20px',
        alignItems: 'center',
        flexWrap: 'wrap' as const,
        '@media (max-width: 822px)': {
            flexDirection: 'column',
        },
        '@media (max-width: 777px)': {
            justifyContent: 'center',
        },
        '@media (max-width: 666px)': {
            flexDirection: 'column',
        },
    },
    displayButton: {
        width: '120px',
        maxHeight: '32px',
    },
    clearButton: {
        color: '#004187',
        cursor: 'pointer',
    },
    filterbox: {
        minWidth: '120px',
        backgroundColor: 'white',
    },
    inputpadding: {
        padding: '0px',
    },
    formcontrol: {
        '& .MuiSelect-outlined': {
            padding: '6px 10px' as const,
        },
        '& .MuiInputLabel-root[data-shrink="false"]': {
            transform: 'translate(14px, 5px) scale(1)' as const,
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: 0,
            maxHeight: '32px',
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: 0,
        },
    },
    inputField: {
        width: '300px',
        '& > div:nth-of-type(1)': {
            backgroundColor: 'white',
            width: '300px',
        },
    },
    inputLabel: {
        color: 'rgb(0, 65, 135)',
        fontSize: '0.9em',
        fontWeight: '600',
    },
    inputLabelWrapper: {
        display: 'flex',
        gap: '5px',
        alignItems: 'center',
        '@media (max-width: 446px)': {
            flexDirection: 'column',
        },
    },
};

export type MagazineFilterCategory =
    'DEHOGA im Fokus'|
    'Schon entdeckt?'|
    'Gut zu wissen'|
    'Brandenburger Sternenhimmel'|
    'Willkommen bei uns'|
    'Nachgefragt'|
    '3 Fragen an';

const magazineFilterCategories: MagazineFilterCategory[] = [
    'DEHOGA im Fokus',
    'Schon entdeckt?',
    'Gut zu wissen',
    'Brandenburger Sternenhimmel',
    'Willkommen bei uns',
    // 'Nachgefragt',
    '3 Fragen an',
];

export interface MagazineFilter {
    category: MagazineFilterCategory|undefined;
}

interface MagazineFilterBarProps {
    selectedIndex: number;
    content: NewsletterContent;
    contents: NewsletterContent[];
    filter: MagazineFilter;
    onFilterChange: (update: (oldFilter: MagazineFilter) => MagazineFilter) => void;
    onMagazineChange: (index: number) => void;
}

const MagazineFilterBar = (props: MagazineFilterBarProps) => {

    const changeFilterCategory = (event: SelectChangeEvent<MagazineFilterCategory>) => {
        props.onFilterChange(oldFilter => ({
            ...oldFilter,
            category: event.target.value as any,
        }));
    };

    const changeMagazine = (event: SelectChangeEvent) => {
        props.onMagazineChange(parseInt(event.target.value));
    };

    return (
        <Box sx={styles.root}>
            <Box sx={styles.barbackground}>
                <Box sx={styles.inputLabelWrapper}>
                    <Box sx={styles.inputLabel}>
                        Ausgabe:
                    </Box>
                    <FormControl sx={styles.formcontrol}>
                        <Box sx={styles.inputField}>
                            <Select
                                value={props.selectedIndex.toString()}
                                onChange={changeMagazine}
                                displayEmpty={true}
                            >
                                {props.contents.map((item, index) => {
                                    return (
                                        <MenuItem
                                            key={index}
                                            value={index}
                                        >{item.title}</MenuItem>
                                    );
                                })}
                            </Select>
                        </Box>
                    </FormControl>
                </Box>
                <Box sx={styles.inputLabelWrapper}>
                    <Box sx={styles.inputLabel}>
                        Kategorie:
                    </Box>
                    <FormControl sx={styles.formcontrol}>
                        <Box sx={styles.inputField}>
                            <Select value={props.filter.category} onChange={changeFilterCategory} displayEmpty={true}>
                                <MenuItem value={undefined}>Alle</MenuItem>
                                {magazineFilterCategories.map(category => (
                                    <MenuItem key={category} value={category}>{category}</MenuItem>
                                ))}
                            </Select>
                        </Box>
                    </FormControl>
                </Box>
            </Box>
        </Box>
    );
};

export default MagazineFilterBar;
