import {Box} from '@mui/material';
import HeaderBackground from './Header/HeaderBackground';
import HeaderContent from './Header/HeaderContent';

const styles = {
    root: {
        height: 410,
        position: 'relative' as 'relative',
        '@media (max-width: 889px)': {
            height: 530,
        },
        '@media (max-width: 534px)': {
            height: 600,
        },
        '@media (max-width: 260px)': {
            height: 650,
        },
    },
};

interface HeaderProps {
    type?: string;
    headline?: string;
    subline?: string;
};

const Header = (props: HeaderProps) => {
    return (
        <Box sx={styles.root}>
            <HeaderBackground type={props.type} />
            <HeaderContent
                type={props.type}
                headline={(props.type === 'magazine') ? 'Brandenburger Gastlichkeit' : 'GASTGEWERBE IN BRANDENBURG'}
                subline={(props.type === 'magazine') ? 'Das Magazin' : 'AKTUELLES AUS DER REGION'}
            />
        </Box>
    );
};

export default Header;
