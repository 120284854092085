import React from 'react';
import {Box} from '@mui/material';
import ImageType from './ImageType';
import {Link} from 'react-router-dom';
import isExternalLink from './isExternalLink';
import getDefaultTarget from './getDefaultTarget';

const styles = {
    root: {
        width: '100%',
        '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'contain',

        },
    },
};

interface ImageProps {
    image: ImageType;
}



const Image = (props: ImageProps) => {
    const linkStyle = {
        cursor: 'pointer',
    };

    const isExternalLinkBool = props.image.link ? isExternalLink(props.image.link) : false;
    const defaultTarget = props.image.link ? getDefaultTarget(isExternalLinkBool) : '_self';

    return (
        <Box id={'root'} sx={styles.root}>
            {props.image.link ?
                isExternalLinkBool ?
                    <img
                        style={linkStyle}
                        src={props.image.imagePath}
                        onClick={() => {
                            window.open(props.image.link,
                                props.image.target || defaultTarget,
                            );}}
                    />
                    :
                    <Link
                        style={linkStyle}
                        to={props.image.imagePath}
                        target={
                            props.image.target || defaultTarget
                        }
                    >
                        <img src={props.image.imagePath} />
                    </Link>
                :
                <img src={props.image.imagePath} />
            }
        </Box>
    );
};

export default Image;
