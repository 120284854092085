import React, {useEffect, useState} from 'react';
import {Box} from '@mui/material';
import Footer from '../common/Footer';
import Header from '../common/Header';
import HighlightBar from '../common/Content/HighlightBar';
import TextWithSidebar from '../common/Content/Tiles/TextWithSidebar';
import TileList from '../common/Content/Tiles/TileList';
import TextTileBox from '../common/Content/Tiles/TextTileBox';
import Adbox from '../common/Adbox';
import ClassificationTile from '../common/Content/Tiles/ClassifiactionTile/ClassificationTile';
import NewMembers from '../common/Content/Tiles/NewMembers';
import MagazineFilterBar, {MagazineFilter} from '../common/Content/MagazineFilterBar';
import {useSelector} from 'react-redux';
import Selector from '../../state/selector';
import cmsContainerContentIdType from '../basic/cmsContainerContentIdType';
import contentListType from '../basic/contentListType';
import LimitText from '../basic/LimitText';
import formatDate from '../basic/formatDate';

const styles = {
    root: {
    },
    contentSection: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        '& > div': {
        },
        '& > div:not(.noMargin)': {
            marginBottom: '40px',
        },
    },
    welcome: {
        color: 'rgb(0, 65, 135)',
        fontSize: '1.5em',
        justifyContent: 'space-between',
        fontWeight: '600',
        display: 'flex',
        width: '100%',
        maxWidth: '1270px',
        marginTop: '150px',
        flexWrap: 'wrap',
        '@media only screen and (max-width: 1300px)': {
            padding: '10px',
            width: 'calc( 100% - 20px)',
        },
    },
};

const getValidContentIds = (contentInfos: any[]) => {
    return contentInfos.map(
        (info) => {
            return info.content_id;
        },
    );
};

const fetchContent = (id: string, contents: any) => {

    const selectedContent = contents.map(
        (content: any) => {
            if (id === content.id) {
                return content;
            }
        },
    ).filter((content: any) => content !== undefined);
    return selectedContent[0] || null;
};

const fetchTopicContents = (contentList: cmsContainerContentIdType[], contents: contentListType) => {

    const member: any = [];
    const classifications: any = [];
    const offers: any = [];
    const fromSupportGroup: any = [];
    const politicsAndEconomy: any = [];
    const associationLife: any = [];
    const whatMovesUs: any = [];
    const dehogaFocus: any = [];
    const alreadyDiscovered: any = [];
    const goodToKnow: any = [];
    const asked: any = [];
    const threeQuestions: any = [];
    const toGuest: any = [];

    for (let index = 0; index < contentList.length; index++) {
        const current = contentList[index];


        if (parseInt(current.container_id) === 20) {
            member.push(fetchContent(current.content_id, contents.memberContents));
        }
        if (parseInt(current.container_id) === 21) {
            classifications.push(fetchContent(current.content_id, contents.classificationContents));
        }

        if (parseInt(current.container_id) === 16) {
            const fetchedContent = fetchContent(current.content_id, contents.newsletterContents);
            switch (fetchedContent ? fetchedContent.categorys : '') {
                case 'Gut zu wissen':
                    goodToKnow.push(fetchedContent);
                    break;
                case 'DEHOGA - im Fokus':
                    dehogaFocus.push(fetchedContent);
                    break;
                case 'Schon entdeckt?':
                    alreadyDiscovered.push(fetchedContent);
                    break;
                case 'Angebote':
                    offers.push(fetchedContent);
                    break;
                case 'Aus dem Förderkreis':
                    fromSupportGroup.push(fetchedContent);
                    break;
                case 'Aktuelles aus Politik & Wirtschaft':
                    politicsAndEconomy.push(fetchedContent);
                    break;
                case 'Verbandsleben':
                    associationLife.push(fetchedContent);
                    break;
                case 'Was uns bewegt':
                    whatMovesUs.push(fetchedContent);
                    break;
                case 'Nachgefragt':
                    const contactIdInterviewOne = fetchedContent.contact[0].content_id;
                    if (contents && contents.contactsContents && contactIdInterviewOne) {
                        fetchedContent.contact = contents.contactsContents.filter(
                            (content: any) => content['id'] === contactIdInterviewOne.toString(),
                        );
                    }
                    asked.push(fetchedContent);
                    break;
                case '3 Fragen an':
                    const contactIdInterviewTwo = fetchedContent.contact[0].content_id;
                    if (contents && contents.contactsContents && contactIdInterviewTwo) {
                        fetchedContent.contact = contents.contactsContents.filter(
                            (content: any) => content['id'] === contactIdInterviewTwo.toString(),
                        );
                    }
                    threeQuestions.push(fetchedContent);
                    break;
                case 'Zu Gast bei':
                    toGuest.push(fetchedContent);
                    break;
                default:
                    break;
            }
        }
    }

    const outputData = {
        classificationList: classifications.filter((content: any) => content !== undefined),
        memberList: member.filter((content: any) => content !== undefined),
        offers: offers,
        fromSupportGroup: fromSupportGroup,
        politicsAndEconomy: politicsAndEconomy,
        associationLife: associationLife,
        whatMovesUs: whatMovesUs,
        dehogaFocus: dehogaFocus,
        alreadyDiscovered: alreadyDiscovered,
        goodToKnow: goodToKnow,
        asked: asked,
        threeQuestions: threeQuestions,
        toGuest: toGuest,
    };
    return outputData;
};

const MagazinePageMain = () => {
    const [magazineFilter, setMagazineFilter] = useState<MagazineFilter>({ category: undefined });

    const contentType = {
        name: 'magazine',
        buttonColor: 'rgb(190, 159, 86)',
        highlightColor: 'rgb(190, 159, 86)',
    };

    const eventContents = useSelector(Selector.Content.eventContents);
    const memberContents = useSelector(Selector.Content.memberContents);
    const contactsContents = useSelector(Selector.Content.contactContents);
    const newsletterContents = useSelector(Selector.Content.newsletterContents);
    const classificationContents = useSelector(Selector.Content.classificationContents);
    const bannerContents = useSelector(Selector.Content.bannerContents);


    const [componentMounted, setComponentMounted] = useState<boolean>(false);
    useEffect(() => {
        if (
            eventContents.length > 0 &&
            memberContents.length > 0 &&
            contactsContents.length > 0 &&
            newsletterContents.length > 0 &&
            classificationContents.length > 0 &&
            bannerContents.length > 0
        ) {
            setComponentMounted(true);
        }

    }, [eventContents, memberContents, contactsContents, newsletterContents, classificationContents, bannerContents]);


    const [urlJumpMark, setUrlJumpMark] = useState<string>();
    useEffect(() => {
        setUrlJumpMark(window.location.href.split('#')[1]);
    }, [urlJumpMark]);


    useEffect(() => {
        if (componentMounted) {
            if (urlJumpMark) {
                const target = document?.getElementById(urlJumpMark) && document.getElementById(urlJumpMark);
                if (target) {
                    target.scrollIntoView();
                }

            }


        }
    }, [componentMounted]);

    const contents = newsletterContents.filter(content => content['categorys'] === 'Vorwort').slice(0).reverse();

    let initialIndex = 0;
    if(contents.length > 0){
        initialIndex = contents.length - 1;
    }
    const [selectedMagazin, setMagazine] = useState<number>(initialIndex);
    const currentContent = contents[selectedMagazin];

    const topicContent = currentContent ? fetchTopicContents(currentContent.topicContents, {
        eventContents: eventContents,
        memberContents: memberContents,
        contactsContents: contactsContents,
        newsletterContents: newsletterContents,
        classificationContents: classificationContents,
    }) : undefined;

    const validBannerContentIds = getValidContentIds(currentContent ? currentContent.banner : []);
    const banner = bannerContents.filter(content => validBannerContentIds.includes(content.id));

    const validEventContentIds = getValidContentIds(currentContent ? currentContent.events : []);
    const events = eventContents
        .filter(content => validEventContentIds.includes(content.id))
        .sort((e1, e2) => {
            if (e1.date < e2.date) return -1;
            else if (e1.date > e2.date) return 1;
            else return 0;
        })
        .slice(0, 3);

    const contactId = currentContent?.contact ? currentContent.contact[0].content_id : [];
    const selectedContact = currentContent?.contact ?
        contactsContents.filter(content => content['id'] === contactId)[0]
        :
        null;

    const showDehogaImFokus = !magazineFilter.category || magazineFilter.category === 'DEHOGA im Fokus';
    const showSchonEntdeckt = !magazineFilter.category || magazineFilter.category === 'Schon entdeckt?';
    const showGutZuWissen = !magazineFilter.category || magazineFilter.category === 'Gut zu wissen';
    const showSternhimmel = !magazineFilter.category || magazineFilter.category === 'Brandenburger Sternenhimmel';
    const showWillkommenBeiUns = !magazineFilter.category || magazineFilter.category === 'Willkommen bei uns';
    const showNachgefragt = !magazineFilter.category || magazineFilter.category === 'Nachgefragt';
    const show3FragenAn = !magazineFilter.category || magazineFilter.category === '3 Fragen an';

    return (
        <Box sx={styles.root}>
            <Header type={contentType.name} />
            <Box sx={styles.contentSection}>
                {selectedContact ?
                    <TextWithSidebar
                        text={currentContent?.text || ''}
                        imageBoxData={selectedContact}
                        bookmarks={events}
                    />
                    : ''}
                <Box sx={styles.welcome}>
                    <Box>
                        Willkommen im DEHOGA Magazin
                    </Box>

                    <Box>
                        {currentContent?.title}
                    </Box>
                </Box>

                {currentContent && (
                    <MagazineFilterBar
                        selectedIndex={selectedMagazin}
                        content={currentContent}
                        contents={contents}
                        filter={magazineFilter}
                        onFilterChange={setMagazineFilter}
                        onMagazineChange={setMagazine}
                    />
                )}

                {Boolean(topicContent && topicContent.dehogaFocus.length > 0 && showDehogaImFokus) &&
                    <>
                        <HighlightBar
                            text={'DEHOGA im Fokus'}
                            detailContentId={topicContent!.dehogaFocus[0].id}
                            detailContentTitle={topicContent!.dehogaFocus[0].title}
                        />
                        {topicContent!.dehogaFocus.map((content: any) => (
                            <TextTileBox
                                key={content.id}
                                backgroundColor={'#f8f5ee'}
                                contentId={content.id}
                                text={LimitText(
                                    content.text,
                                    1000,
                                    content.title,
                                )}
                                title={content.title}
                                date={content.date}
                                image={{
                                    imagePath: content.imagePath,
                                    link: '',
                                    target: '',
                                }}
                                type={contentType}
                            />
                        ))}
                    </>
                }

                {Boolean(topicContent && topicContent.alreadyDiscovered.length > 0 && showSchonEntdeckt) &&
                    <>
                        <HighlightBar
                            text={'Schon entdeckt?'}
                            link={'*'}
                            detailContentId={topicContent!.alreadyDiscovered[0].id}
                            detailContentTitle={topicContent!.alreadyDiscovered[0].title}
                        />
                        <TextTileBox
                            backgroundColor={'#f8f5ee'}
                            contentId={topicContent!.alreadyDiscovered[0].id}
                            text={LimitText(
                                topicContent!.alreadyDiscovered[0].text,
                                1000,
                                topicContent!.alreadyDiscovered[0].title,
                            )}
                            title={topicContent!.alreadyDiscovered[0].title}
                            date={topicContent!.alreadyDiscovered[0].date}
                            image={{
                                imagePath: topicContent!.alreadyDiscovered[0].imagePath,
                                link: '',
                                target: '',
                            }}
                            type={contentType}
                        />
                    </>
                }

                {Boolean(banner && banner.length >= 1) &&
                <Adbox image={{
                    imagePath: banner[0].imagePath,
                    link: banner[0].url,
                    target: '_blank',
                }}
                />
                }

                {Boolean(topicContent && topicContent.memberList.length > 0 && showWillkommenBeiUns) &&
                <>

                    <HighlightBar
                        text={'Willkommen bei uns'}
                        link={'*'}
                        detailContentId={topicContent!.memberList[0].id}
                        detailContentTitle={topicContent!.memberList[0].title}
                        jumpMark={'members'}
                    />
                    <NewMembers
                        title={'Wir begrüßen unsere neuen Mitglieder und Förderkreismitglieder ' +
                        'im DEHOGA Brandenburg. '}
                        text={'Stand: ' + formatDate(currentContent?.stateDate)}
                        members={topicContent!.memberList}
                        limit={0}
                    />
                </>
                }


                {Boolean(banner && banner.length >= 2) &&
                <Adbox image={{
                    imagePath: banner[1].imagePath,
                    link: banner[1].url,
                    target: '_blank',
                }}
                />
                }

                {Boolean(topicContent && topicContent.classificationList.length > 0 && showSternhimmel) &&
                <>
                    <HighlightBar
                        text={'Bandenburger Sternenhimmel'}
                        link={'*'}
                    />

                    <ClassificationTile
                        title={'Brandenburger Sternenhimmel'}
                        text={
                            'Wir gratulieren den klassifizierten Beherbergungsbetrieben zum erreichten ' +
                            'Ergebnis und wünschen Ihnen mit den "Sternen" weiterhin viel geschäftlichen Erfolg.'
                        }
                        stateDate={formatDate(currentContent?.stateDate)}
                        logo={
                            'https://login.insecia.com/api/cms/media-manager/project/200/directory/1/file/2367/raw'
                        }
                        classificationList={topicContent!.classificationList}
                        backgroundColor={'#252e4112'}
                        limit={0}
                    />
                </>
                }


                {Boolean(topicContent && topicContent.goodToKnow.length > 0 && showGutZuWissen) &&
                    <>
                        <HighlightBar
                            text={'Gut zu wissen'}
                            link={'*'}
                        />
                        <TileList imageTileList={topicContent!.goodToKnow} type={contentType} />
                    </>
                }


                {Boolean(topicContent && topicContent.threeQuestions.length > 0 && show3FragenAn) &&
                    <>
                        <HighlightBar
                            text={'3 Fragen an'}
                            link={'*'}
                            detailContentId={topicContent!.threeQuestions[0].id}
                            detailContentTitle={topicContent!.threeQuestions[0].title}
                        />
                        <TextTileBox
                            backgroundColor={'#f8f5ee'}
                            text={LimitText(
                                topicContent!.threeQuestions[0].text,
                                1000,
                                topicContent!.threeQuestions[0].title,
                            )}
                            title={topicContent!.threeQuestions[0].title}
                            profile={topicContent!.threeQuestions[0].contact[0]}
                            type={contentType}
                            textLimit={100}
                            contentId={topicContent!.threeQuestions[0].id}
                        />
                    </>
                }


                {Boolean(topicContent && topicContent.asked.length > 0 && showNachgefragt) &&
                <>
                    <HighlightBar
                        text={'Nachgefragt'}
                        link={'*'}
                        detailContentId={topicContent!.asked[0].id}
                        detailContentTitle={topicContent!.asked[0].title}
                    />

                    <TextTileBox
                        backgroundColor={'#f8f5ee'}
                        text={LimitText(
                            topicContent!.asked[0].text,
                            1000,
                            topicContent!.asked[0].title,
                        )}
                        title={topicContent!.asked[0].title}
                        profile={topicContent!.asked[0].contact[0]}
                        contentId={topicContent!.asked[0].id}
                        type={contentType}
                    />
                </>
                }


            </Box>
            <Footer />
        </Box>
    );
};

export default MagazinePageMain;
