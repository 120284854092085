
const typography = {
    fontFamily: [
        'Roboto',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
    ].join(','),
    fontSize: 14,

    h1: {
        fontFamily: 'Saira Condensed',
        fontSize: '3.2rem',
        fontWeight: 700,
        textTransform: 'uppercase' as const,
    },
    h2: {
        fontFamily: 'Saira Condensed',
        fontSize: '2rem',
        fontWeight: 400,
    },
    h4: {
        fontFamily: 'Saira Condensed',
        fontSize: '2.6rem',
        fontWeight: 700,
        color: '#004187',
    },
    h5: {
        fontFamily: 'Saira Condensed',
        fontSize: '1.4rem',
        fontWeight: 700,
        color: '#004189',
    },
    button: {
        textTransform: 'none' as const,
        fontWeight: 400,
    },
};

export default typography;
