import {call, fork, put, take} from 'redux-saga/effects';
import Command from '../../action/command';
import Event from '../../action/event';
import Api from '../../../api';
import {EventContent} from '../../types/content';
import {ApiCmsContent} from '../../../api/types';
// @ts-ignore
import he from 'he';

const CONTENT_CONTAINER_ID = 17;

const extractEventContentsFromRawContents = (contents: ApiCmsContent[]): EventContent[] => {
    return contents
        .map(content => ({
            id: he.decode(content['id']),
            title: he.decode(content['contents']['titel']['content']),
            place: he.decode(content['contents']['ort']['content']),
            date: he.decode(content['contents']['datum']['plain']),
            text: he.decode(content['contents']['text']['content']),
            image: he.decode(content['contents']['bild']['media_id']),
        }));
};

const onFetchEventContent = function*() {
    const contents: ApiCmsContent[] = yield call(
        Api.fetchContentList,
        CONTENT_CONTAINER_ID,
        ['titel', 'ort', 'datum', 'text', 'bild'],
    );
    const eventContents: EventContent[] = yield call(
        extractEventContentsFromRawContents,
        contents,
    );
    yield put(Event.Content.eventContentsFetched({ eventContents }));
};

export function* fetchEventContentsFlow() {
    while (true) {
        yield take(Command.Content.fetchEventContents.toString());
        yield fork(onFetchEventContent);
    }
};
