
const palette = {
    primary: {
        dark: '#a85a18',
        main: '#f08123',
        light: '#f39a4f',
        contrastText: '#fff',
    },
    secondary: {
        dark: '#856f3c',
        main: '#be9f56',
        light: '#cbb277',
        contrastText: '#fff',
    },
};

export default palette;
