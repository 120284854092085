import {Box} from '@mui/material';
import React from 'react';
const styles = {
    root: {
        display: 'flex',
        alignItems: 'end',
        flexWrap: 'wrap',
    },
    textBox: {
        color: 'gray',
        width: '218px',
        fontSize: '14px',
        '& div:first-of-type': {
            fontWeight: '900',
        },
        '& div:nth-of-type(2)': {
            fontWeight: '500',
        },
    },
    imageWrapper: {
        borderRadius: '1000px',
        overflow: 'hidden',
        width: '200px',
        height: '200px',
        justifyContent: 'center',
        alignItems: 'center',
        '& > img': {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            marginTop: '0!important',
        },
    },
};

interface RoundImageBoxProps {
    size: number;
    imagePath: string;
    title?: string;
    text?: string;
}

const RoundImageBox = (props: RoundImageBoxProps) => {
    styles.imageWrapper.width = props.size + 'px';
    styles.imageWrapper.height = props.size + 'px';
    return (
        <Box sx={styles.root}>
            <Box sx={styles.imageWrapper}>
                <img src={props.imagePath} />
            </Box>
            <Box sx={styles.textBox}>
                <Box>{props.title}</Box>
                <Box>{props.text}</Box>
            </Box>
        </Box>
    );
};

export default RoundImageBox;
