import {Box} from '@mui/material';
import ImageTile from './ImageTile';
import TileTypeImage from './TileTypeImage';
import ContentTypeType from './contentTypeType';

const styles = {
    root: {
        maxWidth: 1270,
        margin: '0 auto',
        marginTop: '40px',
        minHeight: '500px',
        display: 'flex' as const,
        flexDirection: 'row' as const,
        justifyContent: 'left',
        gap: '44px' as const,
        flexWrap: 'wrap' as const,
        width: '100%',
        '@media (max-width: 1308px)': {
            justifyContent: 'center',
        },
    },
};

interface TileListProps {
    imageTileList: TileTypeImage[];
    type?: ContentTypeType;
    validCategorys?: string[];
}

const TileList = (props: TileListProps) => {
    return (
        <Box sx={styles.root}>
            {!props.imageTileList ?
                ''
                :
                props.imageTileList.map((entry: TileTypeImage) => (
                    <ImageTile
                        key={Math.random()}
                        image={entry.imagePath}
                        title={entry.title}
                        text={entry.text}
                        id={entry.id}
                        type={props.type}
                        date={entry.date}
                        locked={entry.locked}
                    />
                ))
            }
        </Box>
    );
};

export default TileList;
