import {ReduxState} from '../reducer';

const initialized = (state: ReduxState) => state.user.initialized;
const user = (state: ReduxState) => state.user.user;
const isLoggedIn = (state: ReduxState) => Boolean(state.user.user);

const UserSelector = {
    initialized,
    user,
    isLoggedIn,
};

export default UserSelector;
