import {Box} from '@mui/material';
import React from 'react';
import BookmarkContainer from './BookmarkContainer';
import RoundImageBox from './RoundImageBox';
import roundProfileType from '../../basic/roundProfileType';
import eventType from '../../basic/eventType';
const styles = {
    root: {
        display: 'flex',
        flexDirection: 'column',
        width: 'calc( 35% - 20px)',
        gap: '50px',
        '@media (max-width: 850px)': {
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
        },
        '@media (max-width: 430px)': {
            paddingLeft: '20px',
            paddingRight: '20px',
            width: 'calc( 100% - 40px)',
            '& > div': {
                justifyContent: 'center',
            },
        },
    },
};

interface SidebarSmallProps {

    bookmarks?: eventType[];
    imageBoxData?: roundProfileType | undefined;
}

const SidebarSmall = (props: SidebarSmallProps) => {
    return (
        <Box sx={styles.root}>
            <RoundImageBox
                size={200}
                imagePath={props.imageBoxData ? props.imageBoxData.imagePath : ''}
                title={props.imageBoxData ? props.imageBoxData.title || props.imageBoxData.name : ''}
                text={props.imageBoxData ? props.imageBoxData.text || props.imageBoxData.position : ''}
            />
            {props.bookmarks ? <BookmarkContainer bookmarks={props.bookmarks} /> : ''}
        </Box>
    );
};

export default SidebarSmall;
