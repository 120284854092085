import {Box, Link} from '@mui/material';
import React from 'react';
import {Link as RouterLink} from 'react-router-dom';
import eventType from '../../basic/eventType';
import htmlEntityDecode from '../../basic/htmlEntityDecode';
import formatDate from '../../basic/formatDate';

const styles = {
    root: {
        width: '100%',
        maxWidth: '400px',
        '&  > div:first-of-type': {
            backgroundColor: 'rgb(0, 65, 135)',
            padding: '7px 14px',
            color: 'white',
            textTransform: 'uppercase' as const,
            fontWeight: '600',
        },
    },
    dataWrapper: {
        fontSize: '18px',
        color: 'black',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    bookmarkLink: {
        color: 'unset',
        textDecoration: 'none',
        '& > div:first-of-type': {
            marginTop: '20px',
        },
        '& > div': {
            width: '100%',
            marginBottom: '15px',
            paddingBottom: '10px',
            borderBottom: '2px solid rgb(0 65 135 / 38%)',
        },
        '&  div > div:first-of-type': {
            marginBottom: '10px',
        },
        '& > div > div': {
            width: 'calc( 100% - 25px)',
            marginLeft: 'auto',
            marginRight: 'auto',
        },
        '& > div > div:first-of-type': {
            color: 'rgb(0, 65, 135)',
        },
        '& > div > div:nth-of-type(2)': {
            fontSize: '16px',
            fontWeight: 'normal',
        },
    },
    title: {
        fontWeight: '600',
    },
    text: {
        display: '-webkit-box',
        height: '-webkit-fit-content',
        maxHeight: '80px',
        WebkitLineClamp: '2',
        WebkitBoxOrient: 'vertical',
        overflow: 'hidden',
    },

};

const decodeEntity = (text: string) => {
    var txt = document.createElement('textarea');
    txt.innerHTML = text;
    return txt.value;
};

interface BookmarkContainerProps {
    bookmarks: eventType[];
}

const BookmarkContainer = (props: BookmarkContainerProps) => {
    return (
        <Box sx={styles.root}>
            <Box>Vormerken!</Box>
            <Box sx={styles.dataWrapper}>
                {props.bookmarks.map((bookmark) => (
                    <Link
                        key={bookmark.id}
                        sx={styles.bookmarkLink}
                        component={RouterLink}
                        to={`/event/${bookmark.id}`}
                    >
                        <Box key={bookmark.id}>
                            <Box sx={styles.title}>
                                {formatDate(bookmark.date) ? formatDate(bookmark.date) + ' | ' : ''}
                                {bookmark.title}
                            </Box>
                            <Box sx={styles.text}>
                                {decodeEntity(htmlEntityDecode(bookmark.text))}
                            </Box>
                        </Box>
                    </Link>
                ))}
            </Box>
            {/* TEMPORARILY DISABLED
            <Box>
                <RouterButton title={'alle Termine Ansehen'} link={'*'} color={'rgb(0, 65, 135)'} />
            </Box>
            */}
        </Box>
    );
};

export default BookmarkContainer;
