import {Box} from '@mui/material';
import RightAngleButton from '../basic/RightAngleButton';
import LoginContentList from './Login/LoginContentList';
import LoginBox from './Login/LoginBox';

const styles = {
    root: {
        display: 'flex' as const,
        flexDirection: 'column' as const,
        width: 1270,
        margin: '0 auto',

    },
    headline: {
        color: '#004187',
        fontFamily: 'Saira Condensed',
        fontSize: '2.6rem',
        fontWeight: 700,
    },

    subheadline: {
        color: '#004187',
        fontFamily: 'Saira Condensed',
        fontSize: '1.7rem',
        fontWeight: 700,
        lineHeight: '2rem',
        '& em': {
            color: '#F08123!important',
            fontStyle: 'normal',
        },
    },
    displaygebutton: {
        width: '176px',
        maxHeight: '32px',
    },
    loginwrapper: {
        display: 'flex',
        width: '100%',
    },
};

interface Loginprops {
    headline: string;
    subheadline: string;
}

const Login = (props: Loginprops) => {
    return (
        <Box style={styles.root}>
            <Box style={styles.headline}>
                {props.headline}
            </Box>
            <Box sx={styles.subheadline}>
                <div dangerouslySetInnerHTML={{ __html: props.subheadline}} />
            </Box>
            <Box style={styles.loginwrapper}>
                <LoginContentList />
                <LoginBox />
            </Box>
            <RightAngleButton
                style={styles.displaygebutton}
                variant={'outlined'}
            >
                Jetzt Mitglied werden
            </RightAngleButton>
        </Box>
    );
};

export default Login;
